
import tool_funs from '../tools'
import MobileDetect from "mobile-detect"
const md = new MobileDetect(window.navigator.userAgent);

const validData = {
    validate: {
        type: '报价单ID', msg: '测试校验提示信息'
    }, response: {}
}


const base_tabs = [{ icon: 'fas fa-home', name: 'home', path: '/', title: '主页' }]
const stickyTab = {
    tabs: sessionStorage.getItem('emis-page-sticky-tabs') ? JSON.parse(sessionStorage.getItem('emis-page-sticky-tabs')) : base_tabs,
}
const menuData = {
    // 注释1
    left_menu: {
        status: true,
        menus: [
            // [
            //     {icon: 'fas fa-home', title: '主页',to: '/', name: 'home',},                
            // ],
            [
                {icon: 'fas fa-database', title: '物料数据', caption: '', group: '/materialdata/', name: 'materialdata', to: '/data/material/'}

            ],
            [
                {
                    icon: 'fas fa-bullhorn', title: '待办事项', caption: '', group: '/client/', name: 'client', to: '', children: [
                        { icon: 'fas fa-calendar-alt', title: '日程安排', group: '', name: 'client', to: '/client/' },
                        { icon: 'fas fa-clipboard', title: '我的任务', group: '', name: 'client', to: '/client/' },
                        { icon: 'far fa-bell', title: '我的消息', group: '', name: 'client', to: '/client/' },

                    ]
                },
            ],
            [
                {
                    icon: 'fas fa-cloud', title: '电子订单', caption: '', group: '/e-order/', name: 'erporder', to: '/e2e/', children: [
                        {
                            icon: 'fas fa-file-alt', title: '订单数据', group: '/e-order/orders/', name: 'erporder', to: '', children: [
                                { icon: 'fas fa-file-alt', title: '采购订单', group: '/e-order/orders/', name: 'purchase', to: '/e-order/orders/purchase/' },
                                { icon: 'fas fa-file-alt', title: '订单查询', group: '/e-order/orders/', name: 'payable', to: '/e-order/orders/payable' },
                                { icon: 'fas fa-hand-holding-usd', title: '应付款', group: '/e-order/orders/', name: 'payable', to: '/e-order/orders/payable/' },
                                { icon: 'fas fa-puzzle-piece', title: '序列号', group: '/e-order/orders/', name: 'serialnumber', to: '/e-order/orders/serialnumber/' },
                                { icon: 'fas fa-receipt', title: '特价单', group: '/e-order/orders/', name: 'spr', to: '/e-order/orders/spr/' },
                                { icon: 'fas fa-receipt', title: '询价单', group: '/e-order/', name: 'inquirySheetIndex', to: '/e-order/inquirySheet/' },
                            ]
                        },
                        {
                            icon: 'fas fa-qrcode', title: '扫码入库', group: '/e-order/receipt/', name: '', to: '', children: [
                                { icon: 'fas fa-file-alt', title: '收货数据', group: '/e-order/receipt/', name: 'notice', to: '/e-order/receipt/notice/' },
                                { icon: 'fas fa-qrcode', title: '扫码收货', group: '/e-order/receipt/', name: 'scan', to: '/e-order/receipt/scan/' },
                            ]
                        },
                        {
                            icon: 'fas fa-database', title: '基础数据', group: '/e-order/basic/', name: 'basic', to: '/e-order/basic/', children: [
                                { icon: 'fas fa-cube', title: '物料数据', group: '/e-order/basic/', name: 'siemensmaterial', to: '/e-order/basic/material/' },
                                { icon: 'fab fa-creative-commons-remix', title: 'BOM', group: '/e-order/basic/', name: 'siemensbom', to: '/e-order/basic/bom/' },
                                { icon: 'fas fa-address-book', title: '收货地址', group: '/e-order/basic/', name: 'receiptaddress', to: '/e-order/basic/receipt-address/' },
                            ]
                        },
                        {
                            icon: 'fas fa-chart-pie', title: '数据分析', group: '/e-order/charts/', name: 'charts', to: '', children: [
                                { icon: 'fas fa-chart-bar', title: '价格分析', group: '/e-order/charts/', name: 'pricechart', to: '/e-order/charts/price/' },
                                { icon: 'fas fa-chart-line', title: '货期分析', group: '/e-order/charts/', name: 'deliverychart', to: '/e-order/charts/delivery/' },
                                { icon: 'fas fa-chart-area', title: '综合分析', group: '/e-order/charts/', name: 'serialnumber', to: '' },
                                { icon: 'fas fa-file-contract', title: '订单跟踪', group: '/e-order/charts/', name: 'siemensbom', to: '' },

                            ]
                        },
                    ]
                },
            ],
            [
                {
                    icon: 'fas fa-filter', title: '选型工具', caption: '', group: '/selector/', name: 'selector', to: '', children: [
                        { icon: 'fas fa-funnel-dollar', title: '选型工具', group: '', name: 'selector', to: '/selector/' },
                    ]
                },
            ],
            [
                {
                    icon: 'fas fa-atlas', title: '客户中心', caption: '', group: '/customer/', name: 'customer', to: '', children: [

                        { icon: 'far fa-id-card', title: '客户管理', group: '', name: 'customerlist', to: '/customer/' },
                        { icon: 'fas fa-people-arrows', title: '客户需求', group: '', name: 'client', to: '/client/' },
                        { icon: 'fas fa-briefcase', title: '商机项目', group: '', name: 'project', to: '/project/' },

                    ]
                },
            ],
            [

                {
                    icon: 'fas fa-shopping-bag', title: '销售管理', caption: '', group: '/client/', name: 'client', to: '/client/', children: [
                        { icon: 'fas fa-file-archive', title: '报价单', group: '/client/', name: 'quotationList', to: '/client/quotation/list/' },
                        { icon: 'fas fa-file-archive', title: '费用报销', group: '/client/', name: 'expense', to: '/client/expense/' },
                        { icon: 'fas fa-file-archive', title: '测试页面', group: '/client/', name: 'quotationTest', to: '/client/quotation/test/' },
                    ]
                },

            ],
            [
                {
                    icon: 'fas fa-handshake', title: '售后服务', caption: '', group: '/client/', name: 'client', to: '', children: [

                        { icon: 'far fa-file', title: '维修申请', group: '', name: 'client', to: '/client/' },
                        { icon: 'far fa-file', title: '保内返修', group: '', name: 'client', to: '/client/' },
                        { icon: 'far fa-file', title: '三方维修', group: '', name: 'client', to: '/client/' },
                        { icon: 'far fa-file', title: '维修报价单', group: '', name: 'client', to: '/client/' },
                    ]
                },
            ],
            [
                {
                    icon: 'fas fa-globe-asia', title: '供应链协同', caption: '', group: '/workorder/', name: 'workorder', to: '', children: [
                        { icon: 'fas fa-file', title: '工单', group: '', name: 'workorder', to: '/workorder/' },
                        { icon: 'fas fa-stamp', title: '合同', group: '', name: 'workorder', to: '/workorder/' },
                        { icon: 'fas fa-luggage-cart', title: '发货', group: '', name: 'dispatchlist', to: '/dispatchlist/' },
                        { icon: 'fas fa-file-invoice-dollar', title: '开票', group: '', name: 'invoice', to: '/invoice/' },
                        { icon: 'fas fa-hand-holding', title: '付款', group: '', name: '', to: '' },
                        { icon: 'fas fa-hand-holding-usd', title: '应付款', group: '', name: '', to: '' },
                    ]
                },
            ],
            [
                {
                    icon: 'fas fa-chalkboard', title: '数据报告', caption: '', group: '/report/', name: 'report', to: '', children: [
                        { icon: 'fas fa-chart-line', title: '物料综合分析', group: '/report/', name: 'material', to: '/report/material/' },
                        { icon: 'fas fa-chart-bar', title: '客户成单率', group: '/report/', name: '', to: '' },
                        { icon: 'fas fa-exclamation-circle', title: '数据预警', group: '/report/', name: '', to: '' },
                    ]
                },
            ],

            [
                {
                    icon: 'fas fa-id-card-alt', title: '用户中心', group: '/functional/', name: 'functional', to: '/functional/',
                    children: [
                        {
                            icon: 'far fa-address-card', title: '组织', group: '/functional/', name: 'organization',
                            to: '/functional/organization/'
                        },
                        {
                            icon: 'far fa-address-card', title: '部门', group: '/functional/', name: 'department',
                            to: '/functional/department/'
                        },
                        {
                            icon: 'far fa-address-card', title: '角色', group: '/functional/', name: 'actor',
                            to: '/functional/actor/'
                        },
                        {
                            icon: 'far fa-address-card', title: '成员', group: '/functional/', name: 'member',
                            to: '/functional/member/'
                        },
                        { icon: 'fas fa-user-cog', title: '个人设置', to: '/configuration/user/' }

                    ]
                },
            ],
            [
                {
                    icon: 'fas fa-cogs', title: '系统管理', caption: '', group: '/system/', name: 'system', to: '/system/', children: [
                        { icon: 'fas fa-sliders-h', title: '系统配置', group: '', name: 'workorder', to: '' },
                        { icon: 'fab fa-windows', title: '服务管理', group: '/system/', name: 'clientservice', to: '/system/client-service/' },
                        { icon: 'fas fa-file-medical-alt', title: '操作日志', group: '', name: 'workorder', to: '' },
                        { icon: 'fas fa-sync', title: '数据同步', group: '', name: 'workorder', to: '' },

                    ]
                },
            ],


        ]
    },
    // 详情抽屉状态
    right_view: {
        status: false,
        page: null//哪个页面的详情
    }
}

const rightDrawer = {
    rightDrawerSource: {
        has: false,
        status: true,
        handle: 0,
        list: [{ "title": "邮箱配置" }, { "title": "E2E接口参数" }, { "title": "客户端任务" }]
    }
}

const state = () => ({
    page: Object.assign({}, validData, menuData, stickyTab, rightDrawer)
})

const getters = {
    getValidate: state => state.page.validate,
    getResponse: state => state.page.response,
    getBaseMenu: state => state.page.left_menu,
    getDetailView: state => state.page.right_view,
    getStickyTabs: state => state.page.tabs,
    getRightDrawerHas: state => state.page.rightDrawerSource.has,
    getRightDrawerStatus: state => state.page.rightDrawerSource.status,
    getRightDrawerSource: state => state.page.rightDrawerSource,

}

const mutations = {
    ['set-right-drawer-has'](state, data) {
        state.page.rightDrawerSource.has = data
    },
    ['set-right-drawer-status'](state, data) {
        state.page.rightDrawerSource.status = data
    },
    ['set-right-drawer-handle'](state, data) {
        state.page.rightDrawerSource.handle = data
    },
    ['change-left-menu-status'](state) {
        state.page.left_menu.status = !state.page.left_menu.status
    },
    // ['change-right-detail-status'](state, data) {
    //     state.page.right_view.status = data
    // },
    // ['change-right-detail-page'](state, data) {
    //     console.log(data, '0000//');
    //     state.page.right_view.page = data
    // },
    ['response-set'](state, data) {
        state.page.response = data
    },
    ['set'](state, data) {
        state.page.validate = data
    },
    ['clean'](state) {
        state.page.validate = {}
    },
    ['add_tab'](state, data) {
        let tabs = state.page.tabs
        let obj = tabs.filter(it => {
            return it.name == data.name
        })
        if (obj.length < 1) {
            tabs.push(data)
            sessionStorage.setItem('emis-page-sticky-tabs', JSON.stringify(tabs))
        }
    },
    ['delete_tab'](state, data) {
        let obj = state.page.tabs.filter(it => {
            return it.name != data.name
        })
        state.page.tabs = obj
        sessionStorage.setItem('emis-page-sticky-tabs', JSON.stringify(obj))
    },
}

const actions = {
    async ['response-success']({ commit }, payload) {
        commit('response-set', { data: payload.data, status: 'positive', status_code: payload.status, status_text: payload.statusText, success_type: payload.success_type, title: payload.success_name })
    },
    async ['response-error']({ commit }, payload) {

        commit('response-set', {
            data: payload.data, status: 'negative', status_code: payload.status,
            status_text: payload.statusText, error_type: payload.error_type, title: payload.error_name
        })
    },
    async ['response-warning']({ commit }, payload) {
        commit('response-set', { data: payload.data, status: 'warning', status_code: payload.status, status_text: payload.statusText, warning_type: payload.warning_type, title: payload.warning_name })
    },
    // form表单校验
    async ['success']({ commit }, payload) {
        commit('set', { msg: payload.data, type: payload.type, status: 'positive' })
    },
    async ['warning']({ commit }, payload) {
        commit('set', { msg: payload.data, type: payload.type, status: 'warning' })
    },
    async ['error']({ commit }, payload) {
        commit('set', { msg: payload.data, type: payload.type, status: 'negative' })
    },
    async ['clean']({ commit }) {
        commit('clean')
    },
    ['add_sticky_tab']({ state, commit }, payload) {
        let ms = state.page.left_menu.menus
        let obj = { icon: 'fas fa-home', name: 'home', path: '/', title: '主页', to: '/' }
        let res = tool_funs.recursive(ms, payload.path)
        if (res.icon) {
            obj.icon = res.icon
            obj.name = res.name
            obj.path = res.to
            obj.title = res.title
        }
        if (obj.name != 'main') {
            commit('add_tab', obj)
        }

    },
    ['delete_sticky_tab']({ commit }, payload) {
        commit('delete_tab', payload)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}