import Dapi from '../apis/default'
const user = localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {}
const clientData = {
    has: false,
    ClientStatus: {},
}
const confsData = {
    clientConf: [
        {index: 0, title: '邮箱配置', formSet: {
            form_class: '', body: [        
                {index: 1, body_class: 'q-gutter-xs row items-start', items:[
                    {index: 1, label: '发送方邮箱', name: 'sender', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: 'SMTP地址', name: 'smtp', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 2, label: 'SSL连接', name: 'type', value: '1', type: 'select', variant:{
                        options: [{label: '开启', value: '1'},{label: '关闭', value: '0'}]
                    }},
                    {index: 1, label: 'SMTP端口', name: 'port', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: '登录用户名', name: 'username', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: '登录密码', name: 'password', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: '接收方邮箱', name: 'recer', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                ]}
            ]
        }},
        {index: 1, title: 'E2E接口参数', formSet: {
            form_class: '', body: [        
                {index: 1, body_class: 'q-gutter-xs row items-start', items:[
                    {index: 1, label: '分销商客户号', name: 'no', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: '分销商ORGID', name: 'orgid', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: '分销商名称', name: 'name', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: '分销商英文简写', name: 'ename', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    
                ]}
            ]
        }},
        {index: 2, title: '客户端任务', formSet: {
            form_class: '', body: [        
                {index: 1, body_class: 'q-gutter-xs row items-start', items:[
                    {index: 1, label: '物料同步时间', name: 'no', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    {index: 1, label: '同步西门子返回间隔（分钟）', name: 'orgid', value: '', type: 'input', placeholder: '', 
                    filled:true, outlined: true,},
                    
                    
                ]}   
            ]
        }},
    ]
}
const state = () => ({
    client: Object.assign({}, clientData, confsData)
})
const getters = {
    getClientHas: state => state.client.has,
    getClientStatus: state => state.client.ClientStatus,
    getclientConf: state => state.client.clientConf,
}
const mutations = {
    ['set-client'](state, data){
        state.client.has = data.length>0 ? true: false
        state.client.ClientStatus = data.length>0 ? data[0]: {}
    },
}
const actions = {
    async ['fetch-client']({commit, dispatch}){
        let param = '?t=' + user.path
        return new Promise((resolve, reject) => {
            Dapi.getClient(param).then(res=>{
                commit('set-client', res.data)
                resolve(true)
            }).catch(error=>{
                error.error_type = 'get_manufacturers'
                error.error_name = '获取厂商数据失败'
                error.statusText = error.data.msg
                dispatch('page/response-error',error, {root: true})
                reject(false)
            })
        })
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}