
function get_menu(tree, group){
    let res = {};
    for (let i = 0; i < tree.length; i++) {
        if (tree[i].to == group) {
            res = tree[i];
            break;
        }        
        if (tree[i].children && tree[i].children.length > 0) {
            res = get_menu(tree[i].children, group);
            if (res && JSON.stringify(res) !== '{}') {
                return res;
            }
        }
    }
    return res;
}
function recursive(tree, group) {
    let res = {}
    
    for(let i=0; i<tree.length;i++){
        res = get_menu(tree[i], group)
        if(res.icon){
            break;
        }
    }
    return res    
}

// 删除对象中空值数据
function sourceObject(data){
    return Object.keys(data)
    .filter((key) => data[key] !== null && data[key] !== undefined)
    .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
}

function getUrlParam(name=''){
    let url = window.location.href
    let dz_url  = url.split('#')[0]
    if(!dz_url){ return false }
    let cs = dz_url.split('?')[1]
    if(!cs){ return false }
    let cs_arr = cs.split('&')
    let ps={}
    cs_arr.forEach(it => {
        ps[it.split('=')[0]] = it.split('=')[1]
    })
    ps = sourceObject(ps)
    if(name){        
        return ps[name] !== undefined ? ps[name] : false
    }else{
        return ps
    }
    
}

function ValidSelectedData(method, length){
    if(method=='multiple'){
        return {
            success: length > 0,
            message: length > 0 ? '' : '请选择至少一行数据进行操作'
        }
    }
    if(method=='single'){
        return {
            success: length == 1,
            message: length == 1 ? '' : '请选择一行数据进行操作'
        }
    }
    return {
        success: false,
        message: '比对数据错误'
    }
}

export default {
    recursive, getUrlParam, ValidSelectedData
}