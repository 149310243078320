import date from 'quasar/src/utils/date.js';
const SearchForm = {
    SearchFormSet: {
        form_class: '', body: [
            { index: 1, label: '订单查询', name: 'keys', value: '', type: 'input', placeholder: '输入单号查询', filled: true, outlined: true },
            {
                index: 2, label: '状态', name: 'status', value: '', type: 'select', variant: {
                    options: [{ label: 'PO', value: 'PO' }, { label: 'SO', value: 'SO' }, { label: 'OA', value: 'OA' }, { label: 'OA_CONF', value: 'OA_CONF' },
                    { label: 'OC', value: 'OC' }, { label: 'INVOICE', value: 'INVOICE' }]
                }
            },

        ]
    }
}
const EorderData = {
    EorderSource: {
        table_class: 'layout-table',
        dense: true,
        data: [
            {
                a:'3008305465',
                b:'YH-PMA-230510',
                c:'Release',
                d:'2023-05-06 16:09:00',
                e:'2023-05-05 11:37:25'
               },
               {
                a:'3008305465',
                b:'YH-PMA-230510',
                c:'Release',
                d:'2023-05-06 16:09:00',
                e:'2023-05-05 11:37:25'
               },
               {
                a:'3008305465',
                b:'YH-PMA-230510',
                c:'Release',
                d:'2023-05-06 16:09:00',
                e:'2023-05-05 11:37:25'
               },
                      
        ],
        visibleColumns: [],
        selection: 'single',
        selected_change: ['eorder', 'table_selected'],
        selected: [],
        columns: [
            {
                check: true, required: false, name: '', label: '西门子SD订单号', align: 'center', field: 'a', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: false, name: '', label: '分销商PO订单号', align: 'center', field: 'b', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: false, name: '', label: '信用状态', align: 'center', field: 'c', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: false, name: '', label: 'OC创建时间', align: 'center', field: 'd', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: false, name: '', label: 'OA创建时间', align: 'center', field: 'e', sortable: true,
                format: (val) => `${val}`
            },
           
        ],
    }
}
const PoForm = {
    PoFormSet: {
        form_class: 'base_form', body: [
            {
                index: 1, body_class: 'q-gutter-xs row items-start', items: [
                    {
                        index: 1, label: '订单类型', name: 'F_OrderType', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true, display: [{ "1": "普通订单" }, { "2": "特价订单" }, { "3": "Bundled订单" }]
                        }
                    },
                    {
                        index: 2, label: '业务公司编码', name: 'F_CompanyCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: 'ERP订单号', name: 'F_BillNo', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '特价订单编号', name: 'F_SPRNo', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '订单创建日期', name: 'F_CreatorDate', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '订单创建人', name: 'F_CreatorName', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '创建人E_Mail', name: 'F_CreatorEmail', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '创建人电话', name: 'F_CreatorTel', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '期望交货日期', name: 'F_ARVDate', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '付款方式编码', name: 'F_PaymentCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '收货地址编码', name: 'F_ReceiptCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '项目名称', name: 'F_ProjectName', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '行业属性', name: 'F_Branch', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '客户名称', name: 'F_CustomerName', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '客户代码', name: 'F_CustomerCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 3, label: '备注', name: 'F_Comments', value: '', type: 'textarea', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
                        }
                    },
                ]
            }

        ]
    }
    // PoFormSet: {
    //      // align: 'center',
    //      style: '',
    //      vertical: true,
    //       body: [
    //         {
    //             index: 1, vertical: true, submit: ['quotation', 'fetch-list'],
    //              items: [
    //                 {
    //                     index: 0, showlabel: true, label: '单据编号', placeholder: '', name: 'quotation_Number', value: '', type: 'input', filled: true, required: true,
    //                     outlined: true, width: '200px', disable: true
    //                 },

    //                 {
    //                     index: 1, label: '订单类型', name: 'F_OrderType', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true, display: [{ "1": "普通订单" }, { "2": "特价订单" }, { "3": "Bundled订单" }]
    //                     }
    //                 },
    //                 {
    //                     index: 2, label: '业务公司编码', name: 'F_CompanyCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: 'ERP订单号', name: 'F_BillNo', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '特价订单编号', name: 'F_SPRNo', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '订单创建日期', name: 'F_CreatorDate', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '订单创建人', name: 'F_CreatorName', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '创建人E_Mail', name: 'F_CreatorEmail', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '创建人电话', name: 'F_CreatorTel', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '期望交货日期', name: 'F_ARVDate', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '付款方式编码', name: 'F_PaymentCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '收货地址编码', name: 'F_ReceiptCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '项目名称', name: 'F_ProjectName', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '行业属性', name: 'F_Branch', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '客户名称', name: 'F_CustomerName', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '客户代码', name: 'F_CustomerCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //                 {
    //                     index: 3, label: '备注', name: 'F_Comments', value: '', type: 'textarea', filled: false, outlined: true, disable: true, variant: {
    //                         origin: ['eorder', 'getPoFormObject'], auto_assignment: true,
    //                     }
    //                 },
    //             ]
    //         }

    //     ]
    // }

}
const PoEntryData = {
    PoEntrysSource: {
        table_class: 'base-table',
        data: [
            {
                a: '01000',
                b: '4509917304',
                c: '',
                d: '1FL6096-1AC61-2AA1',
                e: '',
                f: 'Domestic',
                g: '已发货',
                h: '2023-05-18',
                i: '4.00',
                j: '2023-05-15',
                k: '4.00',
                l: '1FL6096-1AC61-2AA1 SERVOMOTOR 1FL6;3AC',
            },
            {
                a: '01000',
                b: '4509917304',
                c: '',
                d: '6SL3210-5FE13-5UFO',
                e: '',
                f: 'Domestic',
                g: '已发货',
                h: '2023-05-18',
                i: '12.00',
                j: '2023-05-15',
                k: '12.00',
                l: '6SL3210-5FE13-5UFO SINAMICS V90, PROFIN',
            },
            {
                a: '01000',
                b: '4509917304',
                c: '',
                d: '6SL3210-5FE13-5UFO',
                e: '',
                f: 'Domestic',
                g: '已发货',
                h: '2023-05-18',
                i: '12.00',
                j: '2023-05-15',
                k: '12.00',
                l: '6SL3210-5FE13-5UFO SINAMICS V90, PROFIN',
            },

        ],
        visibleColumns: [],
        columns: [
            {
                check: true, required: true, name: '', label: '行号', align: 'left', field: 'a', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '西门子内部PO编号', align: 'left', field: 'b', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '工厂运单号', align: 'left', field: 'c', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '订货号', align: 'left', field: 'd', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '选件', align: 'left', field: 'e', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '供应商', align: 'left', field: 'f', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '发货状态', align: 'left', field: 'g', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '发货时间', align: 'right', field: 'h', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '发货数量', align: 'left', field: 'i', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '收货时间', align: 'left', field: 'j', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '收货数量', align: 'left', field: 'k', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: '西门子物料描述', align: 'left', field: 'l', sortable: true,
                format: (val) => `${val}`
            },
       
        ],
    }
}
const ErpTableData = {
    erpSource: {
        table_class: 'base-table',
        dense: true,
        data: [
            {
                a: '01000',
                b: '000001',
                c: '',
                d: '1FL6096-1AC61-2AA1',
                e: '',
                f: '1FL6096-1AC61-2AA1 SERVOMOTOR 1FL6;3AC',
                g: 'PTO',
                h: '发货完成',
                i: '4509917304',
                j: '18,448.84',
                k: '4.00',
                l: '61.40',
                m: '',
                date1: '20230523',
                date2: '20230516'
            },
            {
                a: '02000',
                b: '000002',
                c: '',
                d: '6SL3210-5FE13-5UFO',
                e: '',
                f: '6SL3210-5FE13-5UFO SINAMICS V90, PROFIN',
                g: 'PTO',
                h: '发货完成',
                i: '4509917303',
                j: '34,859.64',
                k: '12.00',
                l: '61.40',
                m: '',
                date1: '20230606',
                date2: '20230529'
            },
            {
                a: '03000',
                b: '000003',
                c: '',
                d: '6SL3210-5FE13-5UFO',
                e: '',
                f: '6SL3210-5FE13-5UFO SINAMICS V90, PROFIN',
                g: 'PTO',
                h: '发货完成',
                i: '4509917303',
                j: '34,859.64',
                k: '12.00',
                l: '61.40',
                m: '',
                date1: '20230606',
                date2: '20230529'
            },
          
        ],
        visibleColumns: [],
        selection: 'single',
        selected_change: ['eorder', 'table_selected'],
        selected: [],
        columns: [
            {
                check: true, required: false, name: '', label: '行号', align: 'center', field: 'a', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: true, name: '', label: 'ERP订单行号', align: 'center', field: 'b', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '客户物料编码', align: 'center', field: 'c', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '订货号', align: 'center', field: 'd', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '选件', align: 'center', field: 'e', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '物料描述', align: 'center', field: 'f', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '物料分类', align: 'center', field: 'g', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '货物状态', align: 'center', field: 'h', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '内部PO单号', align: 'center', field: 'i', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '物料价格', align: 'right', field: 'j', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '订购数量', align: 'right', field: 'k', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '折扣(%)', align: 'right', field: 'l', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '产品组', align: 'center', field: 'm', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: '', label: '工厂确认的交付时间', align: 'left', field: 'date1', sortable: true,
                format: (val) => `${val ? val : ''}`
                // format: (val) => date.formatDate(val, 'YYYY-MM-DD HH:mm:ss')
            },
            {
                check: true, required: false, name: '', label: '预计发货日期', align: 'left', field: 'date2', sortable: true,
                format: (val) => `${val ? val : ''}`
                // format: (val) => date.formatDate(val, 'YYYY-MM-DD HH:mm:ss')
            },







        ],
    }
}
const DNData = {
    DNEntrysSource: {
        table_class: 'base-table',
        data: [
            {
                a: '01000',
                b: '4107048402',
                c: '1FL6096-1AC61-2AA1',
                d: '',
                e: '4.00',
                f: '4.00',
                g: '2023-05-18 07:38:28',
                h: '2023-05-18 07:38:28',
            },
            {
                a: '02000',
                b: '4107116917',
                c: '6SL3210-5FE13-5UFO',
                d: '',
                e: '12.00',
                f: '12.00',
                g: '2023-05-18 07:38:28',
                h: '2023-05-18 07:38:28',
            },
            {
                a: '03000',
                b: '4107116917',
                c: '6SL3210-5FE13-5UFO',
                d: '',
                e: '12.00',
                f: '12.00',
                g: '2023-05-18 07:38:28',
                h: '2023-05-18 07:38:28',
            },
        ],
        visibleColumns: [],
        columns: [
           
            {
                check: true, required: true, name: '', label: '行号', align: 'left', field: 'a', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '发货单号', align: 'left', field: 'b', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '订货号', align: 'left', field: 'c', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '选件', align: 'left', field: 'd', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '订购数量', align: 'right', field: 'e', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '发货数量', align: 'left', field: 'f', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: 'DN创建时间', align: 'left', field: 'g', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '货物出库时间', align: 'left', field: 'h', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
           
        ]
    }
}
const OAOCData = {
    OAOCEntrysSource: {
        table_class: 'base-table',
        data: [
            {
                a: '01000',
                b: 'SI7EH4017039081',
                c: '2023-05-18',
                d: '',
                e: '',
                f: '',
                g: '',
                h: '',
            },
            {
                a: '02000',
                b: 'SI7DA4017102483',
                c: '2023-05-26',
                d: '',
                e: '',
                f: '',
                g: '',
                h: '',
            },
            {
                a: '03000',
                b: 'SI7DA4017102483',
                c: '2023-05-26',
                d: '',
                e: '',
                f: '',
                g: '',
                h: '',
            },
        ],
        visibleColumns: [],
        columns: [
           
            {
                check: true, required: true, name: '', label: '行号', align: 'left', field: 'a', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '货物工厂备妥', align: 'left', field: 'b', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '货已出厂', align: 'left', field: 'c', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '开始主运输', align: 'left', field: 'd', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '到港及报关资料准备', align: 'left', field: 'e', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '开始报关', align: 'left', field: 'f', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '开始本地运输', align: 'left', field: 'g', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: 'LCC货物入库', align: 'left', field: 'h', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
         
          
        ]
    }
}
const OAOCHead = {
    OAOCHeadSource: {
        data: []
    },
    OAOCFormSet: {
        form_class: 'base_form', body: [
            {
                index: 1, body_class: 'q-gutter-xs row items-start', items: [
                    {
                        index: 2, label: '西门子订单号', name: 'F_SiemensOrderNO', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '订货部门', name: 'F_SiemensDept', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '订货联系人', name: 'F_SiemensContact', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '订货联系人电话', name: 'F_SiemensTel', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '订货联系人邮件', name: 'F_SiemensEmail', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '单据编号', name: 'F_BillNo', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: 'PO创建时间', name: 'F_PODate', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '收货地址', name: 'F_ShipAddress', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '收货联系人', name: 'F_ShipContact', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '收货部门', name: 'F_ShipDept', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '收货联系人电话', name: 'F_ShipTel', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '收货联系人邮件', name: 'F_ShipEmail', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '发货方式/运费支付/包装', name: 'F_Incoterms', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '付款方式编码', name: 'F_PaymentCode', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '不含税金额', name: 'F_NetAmount', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '增值税', name: 'F_Vat', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '含税总金额', name: 'F_Total', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: 'OA订单发送的时间', name: 'F_OASendTime', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '期望交货日期', name: 'F_ARVDate', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: 'OC交付时间', name: 'F_LastConfirmDate', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },
                    {
                        index: 2, label: '信用状态', name: 'F_CreditStatus', value: '', type: 'input', filled: false, outlined: true, disable: true, variant: {
                            origin: ['eorder', 'getOAOCHeadFormObject'], auto_assignment: true,
                        }
                    },


                ]
            }

        ]
    }
}

const invoiceData = {
    InvoiceEntrysSource: {
        table_class: 'base-table',
        data: [
            {
                a: '01000',
                b: '1FL6096-1AC61-2AA1',
                c: '',
                d: '5224948864',
                e: '07923854',
                f: 'PR2511010',
                g: '282U3R251-ADFS',
                h: '20230616',
                i: '4.00',
                j: '18,448.84',
                k: '2,398.35',
                l: '20,847.19',
                m: '2023-05-17 02:08:57',
            },
            {
                a: '02000',
                b: '6SL3210-5FE13-5UFO',
                c: '',
                d: '5224948864',
                e: '07923854',
                f: 'PR2511010',
                g: '282U3R251-ADFS',
                h: '20230616',
                i: '12.00',
                j: '34,859.64',
                k: '4,531.75',
                l: '39,391.39',
                m: '2023-05-30 02:13:01',
            },
            {
                a: '03000',
                b: '6SL3210-5FE13-5UFO',
                c: '',
                d: '5224948864',
                e: '07923854',
                f: 'PR2511010',
                g: '282U3R251-ADFS',
                h: '20230616',
                i: '12.00',
                j: '34,859.64',
                k: '4,531.75',
                l: '39,391.39',
                m: '2023-05-30 02:13:01',
            },

        ],
        visibleColumns: [],
        columns: [
          
            {
                check: true, required: true, name: '', label: '行号', align: 'left', field: 'a', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '订货号', align: 'left', field: 'b', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '选件', align: 'left', field: 'c', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: 'Billing号', align: 'left', field: 'd', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: 'VAT发票号', align: 'left', field: 'e', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '利润中心', align: 'left', field: 'f', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '产品层次', align: 'left', field: 'g', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '应付日期', align: 'left', field: 'h', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: 'Billing数量', align: 'right', field: 'i', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '行项目净值', align: 'right', field: 'j', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '行项目税值', align: 'right', field: 'k', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: '行项目总价', align: 'right', field: 'l', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: true, name: '', label: 'Billing创建日期', align: 'left', field: 'm', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ]
    }
}
const packlistData = {
    PacklistEntrysSource: {
        table_class: 'layout-table',
        data: [],
        visibleColumns: [],
        columns: [
            {
                check: true, required: false, name: 'ID', label: '序号', align: 'right', field: 'ID', sortable: true,
                format: (val) => `${val}`
            },
            {
                check: true, required: false, name: 'F_Date', label: '时间', align: 'left', field: 'F_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'F_DeliveryNote', label: '发货通知单号', align: 'left', field: 'F_DeliveryNote', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_PackageNO', label: '箱号', align: 'left', field: 'F_PackageNO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_Length', label: '箱长', align: 'right', field: 'F_Length', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_Wide', label: '箱宽', align: 'right', field: 'F_Wide', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_High', label: '箱高', align: 'right', field: 'F_High', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'F_Volume', label: '体积', align: 'right', field: 'F_Volume', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_UnitOne', label: '体积单位', align: 'left', field: 'F_UnitOne', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_GrossWeight', label: '毛重', align: 'right', field: 'F_GrossWeight', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_UnitTwo', label: '重量单位', align: 'left', field: 'F_UnitTwo', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

        ]
    }
}
const state = () => ({
    eorder: Object.assign({}, SearchForm, EorderData, PoEntryData, PoForm, OAOCData, OAOCHead, DNData, invoiceData, packlistData,ErpTableData)
})
const getters = {
    getSearchFormSet: state => state.eorder.SearchFormSet,
    getEorderSource: state => state.eorder.EorderSource,
    getErpSource: state => state.eorder.erpSource,

    getPoEntrysSource: state => state.eorder.PoEntrysSource,
    getPoFormSet: state => state.eorder.PoFormSet,
    getPoFormObject: state => state.eorder.EorderSource.selected.length > 0 ? state.eorder.EorderSource.selected[0] : {},
    getEorderIsSelected: state => state.eorder.EorderSource.selected.length > 0,
    getOAOCEntrysSource: state => state.eorder.OAOCEntrysSource,
    getOAOCHeadFormSet: state => state.eorder.OAOCFormSet,
    getOAOCHeadFormObject: state => state.eorder.OAOCHeadSource.data.length > 0 ? state.eorder.OAOCHeadSource.data[0] : {},
    getDNEntrysSource: state => state.eorder.DNEntrysSource,
    getInvoiceEntrysSource: state => state.eorder.InvoiceEntrysSource,
    getPacklistEntrysSource: state => state.eorder.PacklistEntrysSource,
}
const mutations = {
    ['set_eorder_data'](state, data) {
        state.eorder.EorderSource.data = data.data
    },
    ['set_eorder_entrys'](state, data) {
        state.eorder.PoEntrysSource.data = data.po_entrys ? data.po_entrys : []
        state.eorder.OAOCHeadSource.data = []
        state.eorder.OAOCEntrysSource.data = []
        if (data.oaoc && data.oaoc.head) {
            state.eorder.OAOCHeadSource.data.push(data.oaoc.head)
            state.eorder.OAOCEntrysSource.data = data.oaoc.entrys
        }
        state.eorder.DNEntrysSource.data = data.dn
        state.eorder.InvoiceEntrysSource.data = data.invoice
        state.eorder.PacklistEntrysSource.data = data.packlist

    }
}
const user = localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {}
const actions = {
    async ['table_selected']({ state, dispatch }, payload) {
        state.eorder.EorderSource.selected = payload
        dispatch('send_fetch_eorder_entrys', payload[0])
    },
    async ['send_fetch_eorder_entrys']({ dispatch }, payload) {
        let pd = { to: 'client', code: 'send', command: 'eorder__fetch_eorder_entrys', message: { uid: user.id, query: payload } }
        dispatch('channel/socket_send', pd, { root: true })
    },
    async ['return_fetch_eorder_entrys']({ commit }, payload) {
        console.log(payload)
        commit('set_eorder_entrys', payload)
    },
    async ['send_fetch_eorder_data']({ dispatch }, payload) {
        // console.log('send_fetch_eorder_data')
        let pd = { to: 'client', code: 'send', command: 'eorder__fetch_eorder_data', message: { uid: user.id, query: payload } }
        dispatch('channel/socket_send', pd, { root: true })
    },
    async ['return_fetch_eorder_data']({ commit }, payload) {
        // console.log(payload)
        commit('set_eorder_data', payload)
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}