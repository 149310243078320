
const baseData = {
    baseData:{
        company: {selected: '', data: []}, //公司
        payment: {selected: '', data: []}, //付款方式
        address: {selected: '', data: []}, // 收货地址
        branch: {selected: '', data: []}, // 行业
        country: {selected: '', data: []}, // 行业
        form: {},
        showQuery: true,
    }
}
const BomData = {
    BomTableSource: {
        table_class: 'layout-table',
        dense: true,
        edit: true,
        hidePagination: true,
        separator: 'cell', //表格边框
        selected: [],
        height: '410px',
        data: [{
            index: 0,
            edit: false,
            ID: 1,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72151AG400XB0',
            department_id: '正确',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72151AG400XB0',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 0,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
          {
            index: 1,
            edit: false,
            ID: 2,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72142BD230XB8',
            department_id: '正确',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72151AG400XB0',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 1,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
          {
            index: 2,
            edit: false,
            ID: 3,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72162BD230XB8',
            department_id: '正确',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72162BD230XB8',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 2,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
          {
            index: 3,
            edit: false,
            ID: 4,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72211BH220XA8',
            department_id: '错误',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72162BD230XB8',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 3,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
          {
            index: 4,
            edit: false,
            ID: 5,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72231HF220XA8',
            department_id: '错误',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72162BD230XB8',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 0,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },{
            index: 0,
            edit: false,
            ID: 6,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72151AG400XB0',
            department_id: '正确',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72151AG400XB0',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 0,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
          {
            index: 1,
            edit: false,
            ID: 7,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72142BD230XB8',
            department_id: '正确',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72151AG400XB0',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 1,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
          {
            index: 2,
            edit: false,
            ID: 8,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72162BD230XB8',
            department_id: '正确',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72162BD230XB8',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 2,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
          {
            index: 3,
            edit: false,
            ID: 9,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72211BH220XA8',
            department_id: '错误',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72162BD230XB8',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 3,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },{
            index: 4,
            edit: false,
            ID: 10,
            F_ParentMaterialNo: '6ES72310HC220XA8',
            F_ParentMaterial: '6ES72231HF220XA8',
            department_id: '错误',
            F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
            F_ParentQTY: '6ES72162BD230XB8',
            F_ParentUnit: '0',
            F_LineNo: '5',
            F_ComponentMaterialNo: '6ES72310HC220XA8',
            F_ComponentMaterial: 0,
            F_ComponentQTY: 1,
            F_ComponentUnit: '个'
          },
        //   {
        //     index: 2,
        //     edit: false,
        //     ID: 11,
        //     F_ParentMaterialNo: '6ES72310HC220XA8',
        //     F_ParentMaterial: '6ES72162BD230XB8',
        //     department_id: '正确',
        //     F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
        //     F_ParentQTY: '6ES72162BD230XB8',
        //     F_ParentUnit: '0',
        //     F_LineNo: '5',
        //     F_ComponentMaterialNo: '6ES72310HC220XA8',
        //     F_ComponentMaterial: 2,
        //     F_ComponentQTY: 1,
        //     F_ComponentUnit: '个'
        //   },
        //   {
        //     index: 3,
        //     edit: false,
        //     ID: 12,
        //     F_ParentMaterialNo: '6ES72310HC220XA8',
        //     F_ParentMaterial: '6ES72211BH220XA8',
        //     department_id: '错误',
        //     F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
        //     F_ParentQTY: '6ES72162BD230XB8',
        //     F_ParentUnit: '0',
        //     F_LineNo: '5',
        //     F_ComponentMaterialNo: '6ES72310HC220XA8',
        //     F_ComponentMaterial: 3,
        //     F_ComponentQTY: 1,
        //     F_ComponentUnit: '个'
        //   },
        //   {
        //     index: 4,
        //     edit: false,
        //     ID: 13,
        //     F_ParentMaterialNo: '6ES72310HC220XA8',
        //     F_ParentMaterial: '6ES72231HF220XA8',
        //     department_id: '错误',
        //     F_ParentOption: 'SIE.FA.PG212.6ES72151AG400XB0',
        //     F_ParentQTY: '6ES72162BD230XB8',
        //     F_ParentUnit: '0',
        //     F_LineNo: '5',
        //     F_ComponentMaterialNo: '6ES72310HC220XA8',
        //     F_ComponentMaterial: 0,
        //     F_ComponentQTY: 1,
        //     F_ComponentUnit: '个'
        //   }
        ],
        visibleColumns: [],
        columns: [
            {check: true,required: true,name: 'ID', headerStyle: 'max-width: 80px', style: 'max-width: 80px', label: '序号',align: 'right',field: 'ID',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,name: 'F_ParentMaterialNo',headerClasses: 'my-special-class',label: '父项物料编号',align: 'right',field: 'F_ParentMaterialNo',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,name: 'F_ParentMaterial',headerClasses: 'my-special-class',label: '父项物料订货号',align: 'left',field: 'F_ParentMaterial',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,name: 'F_ParentOption',headerClasses: 'my-special-class',label: '父项物料选件',align: 'left',field: 'F_ParentOption',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,name: 'F_ParentQTY',headerClasses: 'my-special-class',label: '父项物料数量',align: 'right',field: 'F_ParentQTY',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,name: 'F_ParentUnit',headerClasses: 'my-special-class',label: '父项物料单位',align: 'center',field: 'F_ParentUnit',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,name: 'F_LineNo',headerClasses: 'my-special-class',label: '行号',align: 'right',field: 'F_LineNo',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,name: 'F_ComponentMaterialNo',headerClasses: 'my-special-class',label: '组件物料编号',align: 'left',field: 'F_ComponentMaterialNo',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,name: 'F_ComponentMaterial',headerClasses: 'my-special-class',label: '组件物料订货号',align: 'left',field: 'F_ComponentMaterial',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,name: 'F_ComponentQTY',headerClasses: 'my-special-class',label: '组件物料数量',align: 'right',field: 'F_ComponentQTY',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,name: 'F_ComponentUnit',headerClasses: 'my-special-class',label: '组件物料单位',align: 'center',field: 'F_ComponentUnit',sortable: true,
            format: (val) => `${val}`},
            
        ],
        tools: {
            title: 'BOM主数据'
        }
    }
}
const PurchaseData = {    
    PurchaseTableSource: {
        table_class: 'layout-table',
        dense: true,
        edit: true,
        hidePagination: true,
        selected: [],
        height: '890px',
        separator: 'cell', //表格边框
        data: [
            {
                index: 1,
                edit: false,
                F_LineNo: '01000',
                F_ERPMaterialNum: '6ES72151AG400XB0',
                check: '正确',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72151AG400XB0',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 278.17,
                F_ListPrice: 3135.88           
            },
            {
                index: 2,
                edit: false,
                F_LineNo: '02000',
                F_ERPMaterialNum: '6ES72142BD230XB8',
                check: '正确',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72151AG400XB0',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 1,
                F_Discount: 213.98,
                F_ListPrice: 3135.88            
            },
            {
                index: 3,
                edit: false,
                F_LineNo: '03000',
                F_ERPMaterialNum: '6ES72162BD230XB8',
                check: '正确',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 2,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 4,
                edit: false,
                F_LineNo: '04000',
                F_ERPMaterialNum: '6ES72211BH220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 3,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 5,
                edit: false,
                F_LineNo: '05000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 6,
                edit: false,
                F_LineNo: '06000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 7,
                edit: false,
                F_LineNo: '07000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 8,
                edit: false,
                F_LineNo: '08000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 9,
                edit: false,
                F_LineNo: '09000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 10,
                edit: false,
                F_LineNo: '09000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 11,
                edit: false,
                F_LineNo: '01000',
                F_ERPMaterialNum: '6ES72151AG400XB0',
                check: '正确',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72151AG400XB0',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 278.17,
                F_ListPrice: 3135.88           
            },
            {
                index: 12,
                edit: false,
                F_LineNo: '02000',
                F_ERPMaterialNum: '6ES72142BD230XB8',
                check: '正确',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72151AG400XB0',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 1,
                F_Discount: 213.98,
                F_ListPrice: 3135.88            
            },
            {
                index: 13,
                edit: false,
                F_LineNo: '03000',
                F_ERPMaterialNum: '6ES72162BD230XB8',
                check: '正确',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 2,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 14,
                edit: false,
                F_LineNo: '04000',
                F_ERPMaterialNum: '6ES72211BH220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 3,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 15,
                edit: false,
                F_LineNo: '05000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 16,
                edit: false,
                F_LineNo: '06000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 17,
                edit: false,
                F_LineNo: '07000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 18,
                edit: false,
                F_LineNo: '08000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 19,
                edit: false,
                F_LineNo: '09000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            {
                index: 20,
                edit: false,
                F_LineNo: '09000',
                F_ERPMaterialNum: '6ES72231HF220XA8',
                check: '错误',
                F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
                F_ERPMaterialName: '6ES72162BD230XB8',
                F_ProductOption: '0',
                F_ERPUnit: '5',
                F_QTY: '14258.07',
                F_Amount: 0,
                F_Discount: 2139.98,
                F_ListPrice: 3135.88
            },
            // {
            //     index: 21,
            //     edit: false,
            //     F_LineNo: '01000',
            //     F_ERPMaterialNum: '6ES72151AG400XB0',
            //     check: '正确',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72151AG400XB0',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 0,
            //     F_Discount: 278.17,
            //     F_ListPrice: 3135.88           
            // },
            // {
            //     index: 22,
            //     edit: false,
            //     F_LineNo: '02000',
            //     F_ERPMaterialNum: '6ES72142BD230XB8',
            //     check: '正确',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72151AG400XB0',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 1,
            //     F_Discount: 213.98,
            //     F_ListPrice: 3135.88            
            // },
            // {
            //     index: 23,
            //     edit: false,
            //     F_LineNo: '03000',
            //     F_ERPMaterialNum: '6ES72162BD230XB8',
            //     check: '正确',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 2,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // },
            // {
            //     index: 24,
            //     edit: false,
            //     F_LineNo: '04000',
            //     F_ERPMaterialNum: '6ES72211BH220XA8',
            //     check: '错误',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 3,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // },
            // {
            //     index: 25,
            //     edit: false,
            //     F_LineNo: '05000',
            //     F_ERPMaterialNum: '6ES72231HF220XA8',
            //     check: '错误',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 0,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // },
            // {
            //     index: 26,
            //     edit: false,
            //     F_LineNo: '06000',
            //     F_ERPMaterialNum: '6ES72231HF220XA8',
            //     check: '错误',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 0,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // },
            // {
            //     index: 27,
            //     edit: false,
            //     F_LineNo: '07000',
            //     F_ERPMaterialNum: '6ES72231HF220XA8',
            //     check: '错误',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 0,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // },
            // {
            //     index: 28,
            //     edit: false,
            //     F_LineNo: '08000',
            //     F_ERPMaterialNum: '6ES72231HF220XA8',
            //     check: '错误',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 0,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // },
            // {
            //     index: 29,
            //     edit: false,
            //     F_LineNo: '09000',
            //     F_ERPMaterialNum: '6ES72231HF220XA8',
            //     check: '错误',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 0,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // },
            // {
            //     index: 30,
            //     edit: false,
            //     F_LineNo: '09000',
            //     F_ERPMaterialNum: '6ES72231HF220XA8',
            //     check: '错误',
            //     F_ERPMaterialCode: 'SIE.FA.PG212.6ES72151AG400XB0',
            //     F_ERPMaterialName: '6ES72162BD230XB8',
            //     F_ProductOption: '0',
            //     F_ERPUnit: '5',
            //     F_QTY: '14258.07',
            //     F_Amount: 0,
            //     F_Discount: 2139.98,
            //     F_ListPrice: 3135.88
            // }
        ],
        columns: [
            {check: true,required: true,name: 'index', headerStyle: 'max-width: 80px', style: 'max-width: 80px', label: '序号',align: 'right',field: 'index',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,headerClasses: 'my-special-class',name: 'F_LineNo',label: '行号',align: 'right',field: 'F_LineNo',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,headerClasses: 'my-special-class',name: 'F_ERPMaterialNum',label: '订货号',align: 'left',field: 'F_ERPMaterialNum',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,headerClasses: 'my-special-class',name: 'check',label: '订货号检测',align: 'center',field: 'check',sortable: false,
            format: (val) => `${val?val:''}`, colorSet:'boolean'},
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_ERPMaterialCode',label: 'ERP物料编码',align: 'left',field: 'F_ERPMaterialCode',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_ERPMaterialName',label: 'ERP物料名称',align: 'left',field: 'F_ERPMaterialName',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_ProductOption',label: '选项',align: 'left',field: 'F_ProductOption',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_ERPUnit',label: 'ERP单位',align: 'center',field: 'F_ERPUnit',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_QTY',label: '数量',align: 'right',field: 'F_QTY',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_Amount',label: '金额',align: 'right',field: 'F_Amount',sortable: true,
            format: (val) => `${val}`},            
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_Discount',label: '折扣',align: 'right',field: 'F_Discount',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,headerClasses: 'my-special-class',name: 'F_ListPrice',label: '折后价格',align: 'right',field: 'F_ListPrice',sortable: true,
            format: (val) => `${val}`},
            
        ],
        visibleColumns: [
        ],
        tools: {
            title: '采购订单详情'
        }
    }
}
const BundledData = {
    BundledSource: {
        table_class: 'layout-table',
        dense: true,
        edit: true,
        hidePagination: true,
        selected: [],
        height: '410px',
        tools: {
            title: 'bundle订单详情', 
        },
        separator: 'cell', //表格边框
        data: [ {
            index: 1,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6ES72310HC220XA8',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 0,
            F_ParentAmount: 278.17
          },
          {
            index: 2,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6AV21232GB030AX0',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 1,
            F_ParentAmount: 213.98
          },
          {
            index: 3,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6ES72151AG400XB0',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 2,
            F_ParentAmount: 2139.98
          },
          {
            index: 4,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6ES72142BD230XB8',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 3,
            F_ParentAmount: 2139.98
          },
          {
            index: 5,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6ES72162BD230XB8',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 0,
            F_ParentAmount: 2139.98
          },{
            index: 6,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6ES72151AG400XB0',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 2,
            F_ParentAmount: 2139.98
          },
          {
            index: 7,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6ES72142BD230XB8',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 3,
            F_ParentAmount: 2139.98
          },
          {
            index: 8,
            edit: false,
            F_LineNo: '100X22',
            F_ParentMaterial: '',
            F_ParentQTY: '',
            F_BOMLineNo: '',
            F_MaterialNo: '6ES72162BD230XB8',
            F_QTY: '0',
            F_ListPrice: '258.07',
            F_Amount: '14258.07',
            F_ParentListPrice: 0,
            F_ParentAmount: 2139.98
          }],
        visibleColumns: [],
        columns: [
            {check: true,required: true,name: 'index', headerStyle: 'max-width: 80px', style: 'max-width: 80px', label: '序号',align: 'right',field: 'index',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: true,name: 'F_LineNo',headerClasses: 'my-special-class',label: '订单行号',align: 'right',field: 'F_LineNo',sortable: true,
            format: (val) => `${val}`},
            {check: true,required: false,name: 'F_ParentMaterial',headerClasses: 'my-special-class',label: '父项物料订货号',align: 'left',field: 'F_ParentMaterial',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: true,name: 'F_ParentQTY',headerClasses: 'my-special-class',label: '父项物料数量',align: 'right',field: 'F_ParentQTY',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: true,name: 'F_BOMLineNo',headerClasses: 'my-special-class',label: 'BOM行',align: 'right',field: 'F_BOMLineNo',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: true,name: 'F_MaterialNo',headerClasses: 'my-special-class',label: '组件/订货号',align: 'left',field: 'F_MaterialNo',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_QTY',headerClasses: 'my-special-class',label: '组件/数量',align: 'right',field: 'F_QTY',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_ListPrice',headerClasses: 'my-special-class',label: '组件价格',align: 'right',field: 'F_ListPrice',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_Amount',headerClasses: 'my-special-class',label: '组件金额',align: 'right',field: 'F_Amount',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_ParentListPrice',headerClasses: 'my-special-class',label: '父项价格',align: 'right',field: 'F_ParentListPrice',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_ParentAmount',headerClasses: 'my-special-class',label: '父项金额',align: 'right',field: 'F_ParentAmount',sortable: true,
            format: (val) => `${val?val:''}`},
            
        ]
    }
}
const TipData = {
    TipNoteSource: {
        data: []
    }
}
const formSet = {
    formSet: {form_class: 'base_form', body: [
        {index: 1, body_class: 'q-gutter-sm row items-start', items:[
            {index: 1, label: '业务公司', showlabel: true, name: 'company', value: '西门子中国有限公司', type: 'select', required:true, variant:{
                origin: ['e2e', 'getCompanyData', 'update_base_data'], fields: ['F_CompanyName', 'F_CompanyCode'],
                auto_assignment: true
            },minWidth: 160},
            {index: 2, label: '采购订单',  showlabel: true, name: 'F_BillNo', value: '117D0247', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            },minWidth: 160},
            {index: 3, label: '单据日期',  showlabel: true, name: 'F_CreatorDate', value: '2017-04-11 00:00:00', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            },minWidth: 160},
            {index: 4, label: '期望交货日期',  showlabel: true, name: 'F_ARVDate', value: '2017-04-11 00:00:00', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            },minWidth: 160},
            {index: 5, label: '付款方式',  showlabel: true, name: 'payment', value: '信用额度付款', type: 'select', variant:{
                origin: ['e2e', 'getPaymentData', 'update_base_data'], fields: ['F_PaymentName', 'F_PaymentCode'],
                auto_assignment: true
            },minWidth: 160},
            {index: 6, label: '收货地址',  showlabel: true, name: 'address', value: '', type: 'select', variant:{
                origin: ['e2e', 'getAddressData', 'update_base_data'], fields: ['F_PaymentName', 'F_PaymentCode'],
                auto_assignment: true
            },minWidth: 160},
            {index: 7, label: '制单人',  showlabel: true, name: 'F_CreatorName', value: '王某某', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            },minWidth: 160},
            {index: 8, label: '制单人邮箱',  showlabel: true, name: 'F_CreatorF_ERPUnit', value: 'dxy828@126.com', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            },minWidth: 160},
            {index: 9, label: '制单人电话',  showlabel: true, name: 'F_CreatorTel', value: '', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            },minWidth: 160},
            {index: 10, label: '特价单号',  showlabel: true, name: 'F_SPRNo', value: '', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            },minWidth: 160},
            {index: 11, label: '行业',  showlabel: true, name: 'branch', value: '钢铁', type: 'select', variant:{
                origin: ['e2e', 'getBranchData', 'update_base_data'], fields: ['F_BranchName', 'F_BranchCode'],
                auto_assignment: true
            },minWidth: 160},
            {index: 12, label: '项目名称',  showlabel: true, name: 'F_CreatorName', value: '', type: 'input', filled:true, outlined: true, disable:false,minWidth: 160 },
            {index: 13, label: '最终客户英文名称', showlabel: true, name: 'F_CreatorName', value: '', type: 'input', filled:true, outlined: true, disable:false, minWidth: 160},
            {index: 14, label: '最终客户国家', showlabel: true, name: 'country', value: '中国', type: 'select', variant:{
                origin: ['e2e', 'getCountryData', 'update_base_data'], fields: ['F_CountryName', 'F_CountryCode'],
                auto_assignment: true
            },minWidth: 160},
            {index: 15, label: 'OSD邮箱',  showlabel: true, name: 'F_CreatorName', value: '', type: 'input', filled:true, outlined: true, disable:false,minWidth: 160 },
            {index: 16, label: '备注', showlabel: true, name: 'F_CreatorName', value: '', type: 'input', filled:true, outlined: true, disable:false, 
            rows: 1,minWidth: 160},
        ]},
        {index: 2, body_class: 'q-gutter-md row items-start second-form', items:[
            {index: 1, label: '订单总额',  showlabel: true, name: 'Total_Amount', value: '50046.36', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true
            }},
            {index: 2, label: '信用余额',  showlabel: true, name: 'F_Amount', value: '436.9', type: 'input', filled:false, outlined: true, disable:true, variant:{
                origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true, hint: 'F_Amount'
            },
            append:{
                after:{ btn: { label: '',  showlabel: false, icon: 'fas fa-search', click: []} }
            }
            },
            {index: 3, 
                btns:[
                    {label: '发送订单',  showlabel: false, type: 'submit'},
                    {label: '收起',  showlabel: false, type: 'fold'}
                ] 
            },
        ]}
    ]}
}
const state = () => ({
    e2e: Object.assign({}, baseData, PurchaseData, formSet, BomData, BundledData, TipData)
})
const getters = {  
    getBundledSource: state => state.e2e.BundledSource, 
    getBomTableSource: state => state.e2e.BomTableSource, 
    getBaseData: state => state.e2e.baseData,
    getTipNoteSource: state => state.e2e.TipNoteSource,
    getPurchaseTableSource: state => state.e2e.PurchaseTableSource,
    getFormSet: state => state.e2e.formSet,
    getCompanyData: state => state.e2e.baseData.company.data,
    getFormData: state => state.e2e.baseData.form,
    getPaymentData:state => state.e2e.baseData.payment.data,
    getAddressData:state => state.e2e.baseData.address.data,
    getBranchData:state => state.e2e.baseData.branch.data,
    getCountryData:state => state.e2e.baseData.country.data,
    
}
const mutations = {
    ['set_purchase_data'](state, data){
        // state.e2e.baseData.form = data.form && data.form.length>0 ? data.form[0]: {}
        if (data.form&& data.form.length>0){
            state.e2e.baseData.form = data.form[0]
            let dbs = data.form
            let total = 0
            dbs.forEach(it=>{
                let ck = data.data.filter(ob =>{
                    return ob.F_MaterialNo==it.F_MaterialNo
                })
                it.check = ck.length>0?true:false
                total += parseFloat(it.F_Amount)
            })
            state.e2e.baseData.form.Total_Amount = total.toFixed(2)
            state.e2e.PurchaseTableSource.data = dbs
        }
    },
    ['set_base_data'](state, data){
        data.query.forEach(it=>{
            if(data[it]&&data[it].length>0){
                state.e2e.baseData[it].data = data[it]
            }
        })
    },
    ['set_bom_data'](state, data){
        state.e2e.BomTableSource.data = data.data
    },
    ['set_show'](state){
        state.e2e.formSet.body[1].items[2].btns[1].label = '收起'
        state.e2e.baseData.showQuery = false
        // console.log('state', state.e2e.baseData.showQuery);
        // console.log('state', state.e2e.formSet.body[1].items[2].btns[1].label);
    },
    ['set_hide'](state){
        state.e2e.formSet.body[1].items[2].btns[1].label = '展开'
        state.e2e.baseData.showQuery = true
    },
}
const user = localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {}
const actions = {
    async ['system_matching']({state,commit}){
        let p = state.e2e.PurchaseTableSource.data
        state.e2e.BundledSource.data = []
        state.e2e.TipNoteSource.data = []
        p.forEach(it=>{
            if(!it.check){
                it.F_ParentListPrice = -1
                state.e2e.TipNoteSource.data.push({
                    type: 'danger', message: '【系统匹配】采购单行号'+it.F_LineNo+'物料主数据订货号检测失败，无法匹配BOM数据。'
                })
            }
            state.e2e.BundledSource.data.push(it)
        })
    },
    async ['purchase_source_data']({dispatch}, bn){        
        dispatch('send_fetch_purchase_data', bn)
        dispatch('send_fetch_base_data')
    },
    async ['send_fetch_base_data']({dispatch, state}){
        let query = []
        let keys = Object.keys(state.e2e.baseData)
        keys.forEach(k=>{
            if(state.e2e.baseData[k].data && !state.e2e.baseData[k].data.length>0){
                query.push(k)
            }
        })
        // console.log(query)
        let pd = {to: 'client', code: 'send', command: 'e2e__fetch_base_data', message: {uid: user.id, query: query}}
        dispatch('channel/socket_send', pd, {root: true})
    },
    async ['return_fetch_base_data']({commit}, payload){
        // console.log(payload)
        commit('set_base_data', payload)
    },
    async ['send_fetch_purchase_data']({dispatch}, payload){
        let pd = {to: 'client', code: 'send', command: 'e2e__fetch_purchase_data', message: {uid: user.id, bn: payload}}
        dispatch('channel/socket_send', pd, {root: true})
    },
    async ['return_fetch_purchase_data']({commit}, payload){
        // console.log(payload)
        commit('set_purchase_data', payload)
    },
    async ['send_fetch_bom_data']({dispatch}){
        let pd = {to: 'client', code: 'send', command: 'e2e__fetch_bom_data', message: {uid: user.id}}
        dispatch('channel/socket_send', pd, {root: true})
    },
    async ['return_fetch_bom_data']({commit,dispatch}, payload){
        // console.log(payload)
        commit('set_bom_data', payload)
        dispatch('system_matching')
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}