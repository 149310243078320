import { switchCase } from '@babel/types'
import API from '../../apis/index'
import utils from '@/tools/commonUtil'
const departmentOptions = {
    dep: [], // 上级部门列表
    // 组件的响应式数据定义
    treeSet: {
        data: [],
        node_key: 'name',
        children_key: 'parent_departments',
        default_expand_all: true,
        selected: [],
        tools: [],
        selectedName: '',
        mode: ''
    },
    compareOrigin: {},
}

const memberform = {
    formSet: {
        align: 'left', style: '',
        vertical: true,
        body: [
            // 列表查询
            {
                index: 1, vertical: true, submit:['department', 'fetch-department-list'], items: [
                    {
                        index: 1, showlabel: true, label:'部门名称', placeholder: '请输入关键字查询', 
                        name: 'department', value: '', type: 'input', filled: false, outlined: true, variant: {
                            origin: [], fields:['name']
                        },
                        minWidth: '120px'
                    },
                    {index: 4, label: '查询', type: 'submit', filled:false, outlined: true, showlabel:false,
                        disable:false, variant:{ icon: 'fas fa-search'}},
                ]
            },
            // 详情修改
            {
                index: 1, vertical: true, save:['department', 'fetch-department-add-tree'], items: [
                    {
                        index: 1, showlabel: true, label:'上级部门', placeholder: '请输入上级部门名称', 
                        name: 'department', value: '', type: 'select', filled: false, outlined: true, denseOpts: true, variant: {
                            origin: ['department', 'getList', 'fetch-higher-department-list'], fields:['name', 'id']
                        },
                        field:'_parent',
                        minWidth: '250px'
                    },
                    {
                        index: 1, showlabel: true, label:'部门名称', placeholder: '请输入部门名称', 
                        name: 'department', value: '', type: 'input', filled: false, outlined: true, variant: {
                            origin: [], 
                        },
                        field:'name',
                        minWidth: '250px'
                    },
                    {
                        index: 1, showlabel: true, label:'编码', placeholder: '请输入编码', 
                        name: 'department', value: '', type: 'input', filled: false, outlined: true, variant: {
                            origin: []
                        },
                        field:'idn',
                        minWidth: '250px'
                    },
                    {
                        index: 1, showlabel: true, label:'备注', placeholder: '请输入备注', 
                        name: 'department', value: '', type: 'textarea', filled: false, outlined: true, variant: {
                            origin: [],
                        },
                        field:'notes',
                        rows: 10,
                        cols: 80,
                        minWidth: '250px',
                        height: '100px'
                    },
                    {index: 4, label: '保存', type: 'submit', filled:false, outlined: true, showlabel:false,
                        disable:false, variant:{ icon: 'fas fa-save'}},
                ]
            },
        ]
    }
}

const operation_tools = {    
    buttonsSet: {
        toolClick: ['department', 'tool_click'],
        buttons: [
            // {index: 1, sign:'add', name: '添加', desc: '', icon: 'fas fa-plus', used: 'dialog', show: false,
            // size: 'width: 500px; max-width: 80vw;', no_actions: false, form:{

            // }},
            // {index: 2, sign:'delete', name: '删除', desc: '', icon: 'fas fa-trash', used: 'dialog', show: false, reply:{
            //     warning: {icon: 'fas fa-exclamation-circle', message: '您正在操作删除数据，请确认操作'}, 
            //     submit: ['actor', 'delete-actor']
            // },size: 'width: 500px; max-width: 80vw;', no_actions: false},
            {
                index: 1, label: '新的', dense:true, flat:true, sign:'add', type: 'square', showlabel:false,
                disable:false, btnClick: false, variant:{}
            },
            {
                index: 2, label: '修改', dense:true, flat:true, sign:'edit', type: 'text',
                disable:false, variant:{ icon: 'fas fa-wrench'}, btnClick: false
            },
            {
                index: 3, label: '|', dense:true, flat:true, type: 'separate', showlabel:false,
                disable:false, variant:{ icon: 'fas fa-wrench'}, btnClick: false
            },
            // {
            //     index: 4, label: '查询', sign:'search', type: 'submit', filled:false, outlined: true, showlabel:false,
            //     disable:false, variant:{ icon: 'fas fa-search'}
            // },
            {
                index: 4, label: '删除', dense:true, flat:true, sign:'del', type: 'text',
                disable:false, variant:{ icon: 'fas fa-wrench'}, btnClick: false, delClick: false
            },
            {
                index: 5, label: '退出', dense:true, flat:true, sign:'exit', type: 'text',
                disable:false, variant:{ icon: 'fas fa-wrench'}, btnClick: false
            },
            {
                index: 6, type: 'round',  sign:'set', showlabel:false,
                disable:false, variant:{ icon: 'fas fa-wrench'}, btnClick: false
            }
            // {
            //     index: 7, type: 'square', showlabel:false, 
            //     disable:false, variant:{origin: ['今天', '本周', '本月', '今日收货']}
            // },
        ]
    }

}

const state = () => ({
    department: Object.assign({},memberform, departmentOptions, operation_tools)
})
const getters = {  
    TreeSet: state => state.department.treeSet,
    getList: state => state.department.dep,
    ButtonsSet: state => state.department.buttonsSet,
    InfoSource: state => state.department.treeSet.selected,
    FormSet: state => state.department.formSet
}

const mutations = {
    // 更改所有button状态
    ['tool_click'](state, data){
        state.department.buttonsSet.buttons.forEach(val=> {
            if(val.index === data.index) {
                val.btnClick = !val.btnClick
            }
        })
    }   
}

const actions = {
    // 查询上级部门列表，因为和搜索部门的接口一样，但是不需要传name
    async ['fetch-higher-department-list']({state,dispatch}){
        await API.fetchDepartmentList().then(res => {
            if(res.status == 200){
                state.department.dep = res.data
            }
        })
    },
    // 带参数模糊搜索
    async ['fetch-department-list']({state,dispatch}){
        const name = state.department.formSet.body[0].items[0].value
        if(name){ 
            await API.fetchDepartmentListByName(name).then(res=>{
                state.department.treeSet.data = res.data       
            }).catch(err=>{
                err.data.error_name = "查询失败"
                err.data.error_type = 'fetch-department-list'
                dispatch('page/response-error', err.data, {root: true})
            })
        } else {
            dispatch('fetch-department-tree')
        }
        
    },
    // 不带参数查询部门树
    async ['fetch-department-tree']({state,commit}, payload){
        await API.fetchDepartmentTree().then(res=>{
            state.department.treeSet.data = []          
            state.department.treeSet.data.push(res.data)
            
        })
    },
    // 查单个树节点详情
    async ['get-department-tree-by-name']({state,commit}, payload){
        const name = state.department.treeSet.selectedName
        await API.getDepartmentTreeByName(name).then(res=>{
            const formItem = state.department.formSet.body[1].items
            const detail = res.data[0]
            formItem[0].value = detail._parent_name
            formItem[0].id = detail.id
            formItem[1].value = detail.name
            formItem[2].value = detail.idn
            formItem[3].value = detail.notes
            state.department.compareOrigin = JSON.parse(JSON.stringify(detail)) // 存储对象源数据
        })
    },

    // 新增树节点，可不需要上级id
    // 修改树节点，需要当前节点id
    // todo: 添加了对比对象之后，保存了新内容，再编辑成原来的的内容再点保存会显示无更改，应该点保存详情之后再调用下对应的查询详情接口
    async ['fetch-department-add-tree']({state,commit,dispatch}, payload){
        const FormItems = state.department.formSet.body[1].items
        let data = {}
        if (state.department.treeSet.mode === 'edit') {
            try {
                FormItems.forEach(item => {
                    switch (item.field) {
                        case 'name':
                            data['name'] = item.value
                            break;
                        case 'idn':
                            data['idn'] = item.value
                            break;
                        case 'notes':
                            data['notes'] = item.value
                            break;
                        default:
                            break;
                    }
                })
                const changes = utils.funDifference(data, state.department.compareOrigin) // 对比是否有字段修改
                if (utils.isEmpty(changes)) {
                    dispatch(
                        'page/response-warning',
                        {
                            data: '当前无修改信息!',
                            warning_name: '操作提示'
                        },
                        {root: true}
                    )
                    return
                } else {
                    await API.editDepartmentTree(FormItems[0].id, changes)
                }
            } catch (error) {
                console.log('error', error)
            }
        } else {
            try {
                // 如果上级部门下拉框有单选的值就赋值
                FormItems.forEach(item => {
                    switch (item.field) {
                        case '_parent':
                            data['_parent'] = item.value
                            break;
                        case 'name':
                            data['name'] = item.value
                            break;
                        case 'idn':
                            data['idn'] = item.value
                            break;
                        case 'notes':
                            data['notes'] = item.value
                            break;
                        default:
                            break;
                    }
                })
                await API.addDepartmentTree(data)
                dispatch(
                    'page/response-success',
                    {
                        data: '成功新增一条部门数据，即将刷新!',
                        success_name: '成功操作提示'
                    },
                    {root: true}
                )
            } catch (error) {
                dispatch(
                    'page/response-error',
                    {
                        data: `${error.data.name}`,
                        error_name: '错误操作提示'
                    },
                    {root: true}
                )
            }
        }
    },

    // 删除树节点
    async ['del-department-tree-by-names']({state,commit}, payload){
        const names = state.department.treeSet.selected
        await API.delDepartmentTree(names).then(res=>{
        })
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}