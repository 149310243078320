import axios from 'axios'
import qs from 'qs'
import store from '../store'
import utils from '@/tools/commonUtil'
// axios 配置

const Axios = axios.create({
  baseURL: process.env.VUE_APP_APIHOST,
  timeout: process.env.VUE_APP_APITIMEOUT,
})

// POST传参序列化
Axios.interceptors.request.use((config) => {
  const is_login = store.getters["user/isLogin"]
  const token = utils.isEmpty(store.getters["user/userBase"])? '': store.getters["user/userBase"][0].user.token 
  if (config.method === 'get') {
    config.data = function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" })
    }
  }

  if (is_login) {
    config.headers.Authorization = 'Token ' + token
  }
  return config
}, (error) => {
  return Promise.reject(error)
})
// 返回状态判断
Axios.interceptors.response.use(config => {
  // response.setHeader("Access-Control-Expose-Headers","Content-Disposition")
  if (config.method === 'get'||config.method==='GET') {
    config.url = encodeURI(config.url)
  }
  return config
}, error => {
  console.log(error,'error');
  if (error.response && error.response.status == 401) {
    localStorage.removeItem('authenticated')
    window.location.reload()
  }
  if (error.response && error.response.status == 500) {
    console.log(error)
    store.dispatch('page/response-error', error.response)
  }
  return Promise.reject(error.response,'error')
})

export default Axios

