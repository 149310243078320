import API from '../../apis'
import date from 'quasar/src/utils/date.js';
const actorform = {
    formSet: {
        align: 'left', style: 'min-width:500px',
        vertical: true,
        body: [
            {index: 1, vertical: true,submit:['actor', 'search-list'], items:[                
                {index: 2,showlabel:false, label: '所在部门', name: 'department', value: '', type: 'select',filled:false, 
                outlined: true, variant:{
                    origin: ['department', 'getList', 'fetch-department-list'], fields:['name', 'id']
                }},
                {index: 1,showlabel:false, label: '查询角色', name: 'search', value: '', type: 'unerr-input', filled:false, 
                outlined: true, disable:false, required: false, placeholder: '输入关键字查询', append:{
                    name: '搜索', icon: 'fas fa-search', click_submit: true, enter: true
                }},
            ]},
        ]
    }
}
const actortable = {
    tableSet: {
        data: [],        
        columns: [
            {check: true,required: false,name: 'id',label: 'ID',align: 'right',field: 'id',sortable: true,
                format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'name',label: '名称',align: 'left',field: 'name',sortable: true,
                format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'create_at',label: '生效时间',align: 'left',field: 'create_at',sortable: true,
                format: (val) => date.formatDate(val, 'YYYY-MM-DD HH:mm:ss')},
        ],
        selected: {change: ['actor', 'table-selectd-change'], data: []},
        settings: {
            title: '', row_key: 'id', init: ['actor', 'fetch-actor-list'], selection: 'multiple',table_height: '100%',
            
        },
        
    }
}
const state = () => ({
    actor: Object.assign({}, actorform, actortable)
})

const getters = {
    FormSet: state => state.actor.formSet,
    TableSet: state => state.actor.tableSet,
    getList: state => state.actor.tableSet.data,
}

const mutations = {
    
}

const actions = {
    async ['tools_set']({state, dispatch}, payload){
        let tool = state.actor.tableSet.tools
        tool.active = payload.index
        let button = tool.buttons.filter(it=>{
            return it.index==payload.index
        })[0]
        //合法性验证
        let selected = state.actor.tableSet.selected.data
        if (button.sign==''){
            if (selected.length !=1){
                let error = {error_name: '选择错误', data: '请选择一行数据!'}              
                dispatch('page/response-error',error, {root: true})
                return
            }
        }
        button.show = !button.show
    },
    async ['delete-actor']({commit}, payload){
        console.log(payload)
    },
    async ['table-selectd-change']({state}, payload){
        
        state.actor.tableSet.selected.data = payload
    },
    async ['search-list']({commit}, payload){
        console.log(payload)
    },
    async ['fetch-actor-list']({state}, payload){
        console.log(payload)
        if(state.actor.tableSet.data.length < 1){
            await API.fetchActorList().then(res=>{
                
                state.actor.tableSet.data = res.data
            })
        }
        
    }
   
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}