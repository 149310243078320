import {createRouter, createWebHashHistory} from 'vue-router'
import store from '../store'
import routes from './routers'


//路由模式
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

//登录验证
router.beforeEach(function (to, from, next) {
  
    const is_login = store.getters["user/isLogin"]
    // 判断当前url是否要求验证
    if (to.matched.some(r => r.meta.requiresAuth)) {
        // 判断用户是否登陆，以及是否记录用户信息
        if (!is_login) {
            localStorage.setItem("preRouteName", to.name)
            localStorage.removeItem('authenticated')
            localStorage.removeItem('userBase')
            // 跳转到登录界面
            next('/login')
        } else {
            // 保留在当前url
            next()
        }
        
    } else {
        next()
    }
    // next()
})

export default  router