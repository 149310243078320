import API from '@/apis';
import utils from '@/tools/commonUtil'
const formData = {
    listForm: {
        style: '',
        vertical: true,
        body: [
            {
                index: 1, vertical: true,
                // submit: ['quotation', 'listSearch'], 不想用此方法调用
                items: [
                    {
                        index: 0, showlabel: true, label: '关键字查询', placeholder: '输入关键字查询', name: 'search', value: '', type: 'input', filled: false,
                        outlined: true,
                        width: '200px',
                    },
                    {
                        index: 5, label: '查询', type: 'submit', filled: false, outlined: true, showlabel: false,
                        disable: false, variant: { icon: 'fas fa-search' }
                    },
                ]
            },
        ]
    },
    detailForm: {
        style: '',
        vertical: true,
        body: [
            {
                index: 1, vertical: true, submit: ['member', 'fetch-list'],
                items: [
                    {
                        index: 0, showlabel: true, label: '报销单号id', placeholder: '', name: 'expense_id', value: '0434RD235', type: 'select', filled: true, required: true,
                        outlined: true, width: '200px', disable: false,denseOpts: true,
                        variant: {
                            origin: ['quotation', 'customerList', 'getCustomerList'], fields: ['company', 'id']
                        },
                    },
                    {
                        index: 0, showlabel: true, label: '报销单号', placeholder: '', name: 'expense_number', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '项目/商机号', placeholder: '', name: 'opportunity_number', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', disable: false,denseOpts: true,
                        variant: {
                            origin: ['quotation', 'customerList', 'getCustomerList'], fields: ['company', 'id']
                        },
                    },
                    {
                        index: 0, showlabel: true, label: '客户', placeholder: '', name: 'customer_id', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', disable: false,denseOpts: true,
                        variant: {
                            origin: ['quotation', 'customerList', 'getCustomerList'], fields: ['company', 'id']
                        },
                    },
                    {
                        index: 0, showlabel: true, label: '日期', placeholder: '', name: 'bill_date', value: '', type: 'dateTime', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '销售部门', placeholder: '', name: 'department_id', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', disable: false,denseOpts: true,
                        variant: {
                            origin: ['quotation', 'customerList', 'getCustomerList'], fields: ['company', 'id']
                        },
                    },
                    {
                        index: 0, showlabel: true, label: '销售员', placeholder: '', name: 'salesid', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                   
                   
             
                ]
            },
        ]
    }
}
const tableData = {
    listTable: {
        table_class: 'layout-table',
        height: '400px',
        separator: 'cell', //表格边框
        // row_key: 'id',
        hidePagination: false,
        dense: true,
        edit: false, //带不带编辑功能
        selection: 'multiple',
        selected: [],
        visibleColumns: [ 
           'expense_number','opportunity_number','customer_id','bill_date','department_id','salesid'
        ],
        data:[
            {expense_number:'767FSDF98',opportunity_number:'商机1',customer_id:'方文成拓',bill_date:'2023-03-06',department_id:'销售部',salesid:'张三'},
            {expense_number:'345FSDF98',opportunity_number:'商机2',customer_id:'方文成拓',bill_date:'2023-03-06',department_id:'销售部',salesid:'张四'},
            {expense_number:'432FSDF98',opportunity_number:'商机3',customer_id:'方文成拓',bill_date:'2023-03-06',department_id:'销售部',salesid:'张五'},
        ],
         
        columns:[
            {
                check: true, required: false, name: 'expense_number', label: '报销单号', align: 'left', field: 'expense_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'opportunity_number', label: '项目/商机号', align: 'left', field: 'opportunity_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'customer_id', label: '客户', align: 'left', field: 'customer_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'bill_date', label: '日期', align: 'right', field: 'bill_date', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'department_id', label: '销售部门', align: 'left', field: 'department_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'salesid', label: '销售员', align: 'left', field: 'salesid', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
           
            
           
        ]
    },
    detailTable: {
        table_class: 'layout-table',
        height: '400px',
        // row_key: 'id',
        separator: 'cell',
        hidePagination: true,
        dense: true,
        edit: true,
        selection: 'multiple',
        selected: [],
        visibleColumns: ['entry_id','expense_type','description','amount','notes'],
        data: [
            {index:'1',id:'01',entry_id:'1211',expense_type:'打车费用',description:'西安出差',amount:'598.00',notes:'张三的报销单据'},
            {index:'2',id:'02',entry_id:'1212',expense_type:'住宿费用',description:'上海出差',amount:'698.00',notes:'张四的报销单据'},
            {index:'3',id:'03',entry_id:'1213',expense_type:'其他费用',description:'重庆出差',amount:'798.00',notes:'张五的报销单据'},
        ],
        columns: [
            { name: 'index', label: '序号', field: 'index', align: 'center' },
            {
                check: true, required: false, name: 'entry_id', label: '行号', align: 'center', field: 'entry_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },

            {
                check: true, required: false, name: 'expense_type', label: '费用类型', align: 'left', field: 'expense_type', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'description', label: '费用说明', align: 'left', field: 'description', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'amount', label: '报销金额', align: 'right', field: 'amount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'notes', label: '备注', align: 'left', field: 'notes', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
        ],
        tools: {
            active_index: 1,
            btns: [
                {
                    index: 1, label: '新增行', type: 'text', sign: "add", dense: true, flat: true,
                    disable: false, btnClick: false
                },
                {
                    index: 1, label: '删除行', type: 'text', sign: "delete", dense: true, flat: true,
                    disable: false, btnClick: false
                }
            ]
        }
    },
}
const btnData = {
    listBtn: {
        btns: [
            {
                index: 1, label: '新增', type: 'text', sign: "add", dense: true, flat: true,
                disable: false, btnClick: false, variant: { icon: 'fas fa-search' }
            },
            {
                index: 4, label: '删除', type: 'text', sign: "delete", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 3, label: '|', type: 'separate', sign: "line", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 5, label: '导入', type: 'text', sign: "import", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },

            {
                index: 7, label: '导出', type: 'text', sign: "export", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 2, label: '详情', type: 'text', sign: "detail", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
        ],
    },

    detailBtn: {
        btns: [
            {
                index: 1, label: '返回', type: 'text', sign: "back", dense: true, flat: true,
                disable: false, btnClick: false,
            },

            {
                index: 1, label: '保存', type: 'text', sign: "save", dense: true, flat: true,
                disable: false, btnClick: false, variant: { icon: 'fas fa-search' }
            },
            {
                index: 2, label: '取消', type: 'text', sign: "cancel", dense: true, flat: true,
                disable: false, btnClick: false
            },

        ]
    }
}

const state = () => ({
    expense: Object.assign({}, formData, tableData, btnData)
})

const getters = {
    listForm: state => state.expense.listForm,
    detailForm: state => state.expense.detailForm,
    listTable: state => state.expense.listTable,
    detailTable: state => state.expense.detailTable,
    listBtn: state => state.expense.listBtn,
    detailBtn: state => state.expense.detailBtn,
}
const mutations = {
        //form置空
        initDetailFrom(state) {
            state.expense.detailForm.body[0].items = state.expense.detailForm.body[0].items.map(item => {
                return {
                    ...item, value: null
                }
            })
        },
        //table置空
        initDetailTable(state) {
            state.expense.detailTable.data = []
        },
    //新增行
    addRow(state){
        state.expense.detailTable.data.push({
            id: (Math.random() + new Date().getTime()).toString(32).slice(0, 8),
            index: state.expense.detailTable.data.length + 1
        })
    }

}
const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}