import Dapi from '../../apis/default'
const categorys = {
    local_manufacturer: sessionStorage.getItem('emis-selector-mft-name') ? JSON.parse(sessionStorage.getItem('emis-selector-mft-name')) : '',
    manufacturers: [],
    categorys: []
}
const selector_result = {
    results: {
        mas: [],
        ma_imgs: [],
    }  
}
const selector_list = {
    selector_list: {
        list: [
            {dhh: 'LV510300', mode: 'CVS100B16 TMD F/3P', mft: '施耐德', num: '1', price: '585.08', store: '', desc: 'CVS100B TM16D 断路器 - 3P/3d'},
            {dhh: '', mode: '', mft: '', num: '', price: '', store: '', desc: ''}
        ],
        columns: [            
            {check: true,name: 'dhh',label: '订货号',align: 'left',field: 'dhh',sortable: true,format: (val) => `${val?val:''}`},
            {check: true,name: 'mode',label: '型号',align: 'left',field: 'mode',sortable: true,format: (val) => `${val?val:''}`},
            {check: true,name: 'mft',label: '品牌',align: 'left',field: 'mft',sortable: true,format: (val) => `${val?val:''}`},
            {check: true,name: 'num',label: '数量',align: 'right',field: 'num',sortable: true,format: (val) => `${val?val:''}`},
            {check: true,name: 'price',label: '列表价',align: 'right',field: 'price',sortable: true,format: (val) => `${val?val:''}`},
            {check: true,name: 'store',label: '库存',align: 'right',field: 'store',sortable: true,format: (val) => `${val?val:''}`},
            {check: true,name: 'desc',label: '描述',align: 'left',field: 'desc',sortable: true,format: (val) => `${val?val:''}`},
        ],
        visible_columns: ['dhh', 'mode', 'mft', 'num', 'price',  'desc']
    }
}
const state = () => ({
    selector: Object.assign({}, categorys, selector_result, selector_list)
})
const getters = {
    CTG_loc_mft: state => state.selector.local_manufacturer,
    CTG_mft: state => state.selector.manufacturers, 
    CTG_result: state => state.selector.results,
    CTG_list_num: state => state.selector.selector_list.list.length, 
    CTG_list: state => state.selector.selector_list,  
}
const mutations = {
    ['set_manufacturers'](state,data){
        state.selector.manufacturers = data
    },
    ['set_results'](state, data){
        state.selector.results = data
    },
    ['add_list'](state, data){
        state.selector.selector_list.list.push(data)
    },
}
const actions = {
    async ['add_selector_list']({commit}, payload){
        commit('add_list', payload)
    },
    async ['post_selector_data']({commit, dispatch}, payload){
        return new Promise((resolve, reject) => {
            Dapi.postSelector(payload).then(res=>{  
                commit('set_results', res.data)              
                resolve(true)
            }).catch(error=>{
                error.error_type = 'post_selector_data'
                error.error_name = '选型失败'
                error.statusText = error.data.msg
                dispatch('page/response-error',error, {root: true})
                reject(false)
            })
        })
    },
    async ['post_category']({commit, dispatch}, payload){
        return new Promise((resolve, reject) => {
            Dapi.postCategorys({name: payload}).then(res=>{                
                resolve(res.data)
            }).catch(error=>{
                error.error_type = 'post_category'
                error.error_name = '获取产品类型失败'
                error.statusText = error.data.msg
                dispatch('page/response-error',error, {root: true})
                reject(false)
            })
        })
    },
    async ['get_manufacturers']({commit, dispatch}){
        return new Promise((resolve, reject) => {
            Dapi.getCategoryManufacturers().then(res=>{
                commit('set_manufacturers', res.data)
                resolve(true)
            }).catch(error=>{
                error.error_type = 'get_manufacturers'
                error.error_name = '获取厂商数据失败'
                error.statusText = error.data.msg
                dispatch('page/response-error',error, {root: true})
                reject(false)
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}