
const receiptData = {
    ReceiptSearchFormSet: { form_class: '', body: [        
        {index: 1, label: '收货通知单', name: 'F_KeyDate', value: '', type: 'input', placeholder: '', 
        filled:true, outlined: true},
        {index: 2, label: '订货号', name: 'F_KeyDate', value: '', type: 'input', placeholder: '输入订货号查询', 
        filled:true, outlined: true},
       
    ]},
    ReceiptSource: {
        table_class: 'base-table',
        data: [
            {
                "supplier_mark": "siemens",                
                "hubarcode": "00012342231202500722",
                "dnnumber": "4013350621",
                "dnitemnumber": 1,
                "material_ordernumber": "6ES76566CG332DF0",
                "material_desc": "6ES7656-6CG33-2DF0 SIMATIC PCS 7 CPU 41",                
                "quantity": "2.0",                
                "sonumber": "3006692010",
                "soitemnumber": 1,
                "ponumber": "121J0500",
                "poitemnumber": 1,                
            },
            {
                "supplier_mark": "siemens",                
                "hubarcode": "00012342231201299722",
                "dnnumber": "4013364653",
                "dnitemnumber": 1,
                "material_ordernumber": "6ES78532BE012YQ4",
                "material_desc": "6ES7853-2BE01-2YQ4 PRONETA Prof. V1.1 E",                
                "quantity": "5.0",                
                "sonumber": "3006695654",
                "soitemnumber": 1,
                "ponumber": "121J0535",
                "poitemnumber": 1,                
            },
            {
                "supplier_mark": "siemens",                
                "hubarcode": "00012342231202285537",
                "dnnumber": "4013398452",
                "dnitemnumber": 1,
                "material_ordernumber": "6DL89130DL000AH5",
                "material_desc": "6DL8913-0DL00-0AH5 SIMIT SP V11.0 ENGIN",                
                "quantity": "5.0",                
                "sonumber": "3006715565",
                "soitemnumber": 1,
                "ponumber": "121J0599",
                "poitemnumber": 1,                
            },
            {
                "supplier_mark": "siemens",                
                "hubarcode": "00012342231202285544",
                "dnnumber": "4013277217",
                "dnitemnumber": 1,
                "material_ordernumber": "1LE00041EA233AA4Z",
                "material_desc": "1LE0004-1EA23-3AA4-Z Low Voltage Mo",                
                "quantity": "5.0",                
                "sonumber": "3006828775",
                "soitemnumber": 1,
                "ponumber": "121J0727",
                "poitemnumber": 1,                
            },
        ],
        visibleColumns: [],        
        columns: [
            {check: true,required: false,name: 'supplier_mark',label: '供应商',align: 'left',field: 'supplier_mark',sortable: true,
            format: (val) => `${val?val:''}`},             
            {check: true,required: false,name: 'hubarcode',label: '箱码',align: 'left',field: 'hubarcode',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'dnnumber',label: '发货单号',align: 'left',field: 'dnnumber',sortable: true,
            format: (val) => `${val?val:''}`},            
            {check: true,required: false,name: 'dnitemnumber',label: '发货单行号',align: 'right',field: 'dnitemnumber',sortable: true,
            format: (val) => `${val?val:''}`},               
            {check: true,required: false,name: 'material_ordernumber',label: '物料订货号',align: 'left',field: 'material_ordernumber',sortable: true,
            format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'material_desc',label: '物料描述',align: 'left',field: 'material_desc',sortable: true,
            format: (val) => `${val?val:''}`},             
            {check: true,required: false,name: 'quantity',label: '数量',align: 'left',field: 'quantity',sortable: true,
            format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'weight',label: '重量',align: 'right',field: 'weight',sortable: true,
            format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'weightuom',label: '重量单位',align: 'left',field: 'weightuom',sortable: true,
            format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'sonumber',label: '订单号',align: 'left',field: 'sonumber',sortable: true,
            format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'soitemnumber',label: '订单行号',align: 'right',field: 'soitemnumber',sortable: true,
            format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'ponumber',label: '采购单号',align: 'left',field: 'ponumber',sortable: true,
            format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'poitemnumber',label: '采购单行号',align: 'right',field: 'poitemnumber',sortable: true,
            format: (val) => `${val?val:''}`},            

        ]
    },
}
const state = () => ({
    receipt: Object.assign({}, receiptData)
})
const getters = {
    getReceiptSearchFormSet: state => state.receipt.ReceiptSearchFormSet,
    getReceiptSource: state => state.receipt.ReceiptSource
    
}
const mutations = {

}
const user = localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {}
const actions = {

}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}