import { switchCase } from '@babel/types'
import API from '../apis/index'
const inquirySheetOptions = {
    dep: [], // 上级部门列表
    // 组件的响应式数据定义
    treeSet: {
        data: [],
        node_key: 'name',
        children_key: 'parent_departments',
        default_expand_all: true,
        selected: [],
        tools: [],
        selectedName: '',
        mode: ''
    }
}

const inquirySheetform = {
    formSet: {
        align: 'left', style: '',
        vertical: true,
        body: [
            // 列表查询
            {
                index: 1, vertical: true, submit:['inquirySheet', 'fetch-inquirySheet-list'], items: [
                    {
                        index: 1, showlabel: true, label:'单据编号', placeholder: '请输入关键字查询', 
                        name: 'number', value: '', type: 'input', filled: false, outlined: true, variant: {
                            origin: [], fields:['name']
                        },
                        minWidth: '120px'
                    },
                    {
                        index: 1, showlabel: true, label:'供应商信息', placeholder: '请输入关键字查询', 
                        name: 'info', value: '', type: 'input', filled: false, outlined: true, variant: {
                            origin: [], fields:['name']
                        },
                        minWidth: '120px'
                    },
                ]
            },
            // 详情表单
            {
                index: 2, vertical: true, items: [
                    {
                        index: 1, showlabel: true, label:'单据编号', placeholder: '#568423', 
                        name: 'number', value: '', type: 'input', filled: true, outlined: true, variant: {
                            origin: [], fields:['name']
                        },
                        minWidth: '120px'
                    },
                    {
                        index: 2, showlabel: true, label:'客户内码', placeholder: '3931', 
                        name: 'info', value: '', type: 'input', filled: true, outlined: true, variant: {
                            origin: [], fields:['name']
                        },
                        minWidth: '120px'
                    },
                    {
                        index: 2, showlabel: true, label:'商机/项目内码', placeholder: '393', 
                        name: 'info', value: '', type: 'input', filled: true, outlined: true, variant: {
                            origin: [], fields:['name']
                        },
                        minWidth: '120px'
                    },
                    {
                        index: 2, showlabel: true, label:'单据日期', placeholder: '2020-06-12 20:10:31', 
                        name: 'info', value: '', type: 'input', filled: true, outlined: true, variant: {
                            origin: [], fields:['name']
                        },
                        minWidth: '120px'
                    },
                ]
            },
        ]
    }
}

const inquirySheetData = {
    tableSet: {
        table_class: 'layout-table',
        dense: true,
        edit: false,
        hidePagination: true,
        selection: 'single',
        selected: [],
        separator: 'cell', //表格边框
        data: [
            {
                index: 0,
                ID:1,
                inquiry_Number: '#598235',
                customer_id: '6ES72151AG400XB0',
                check: '正确1',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72151AG400XB0',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'           
            },
            {
                index: 1,
                ID:2,
                inquiry_Number: '#598235',
                customer_id: '6ES72142BD230XB8',
                check: '正确',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72151AG400XB0',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'            
            },
            {
                index: 2,
                ID:3,
                inquiry_Number: '#598235',
                customer_id: '6ES72162BD230XB8',
                check: '正确',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 3,
                ID:4,
                inquiry_Number: '#598235',
                customer_id: '6ES72211BH220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 5,
                ID:5,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 6,
                ID:6,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 7,
                ID:7,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 6,
                ID:8,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 7,
                ID:9,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            }
        ],
        columns: [
            {
                check: true,
                name: 'ID',
                required: true,
                headerStyle: 'max-width: 50px', 
                style: 'max-width: 50px',
                label: '序号',
                align: 'center',
                field: 'ID',
                format: (val) => `${val}`
            },{
                check: true,
                name: 'inquiry_Number',
                headerClasses: 'my-special-class',
                required: true,
                label: '单据编号',
                align: 'right',
                field: 'inquiry_Number',
                format: (val) => `${val}`
            }, {
                check: true,
                required: true,
                name: 'customer_id',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '客户内码',
                field: 'customer_id'
            },{
                check: true,
                required: true,
                name: 'opportunity_id',
                headerClasses: 'my-special-class',
                label: '商机/项目内码',
                align: 'left',
                field: 'opportunity_id',
                sortable: true,
            }, {
                check: true,
                required: true,
                name: 'bill_date',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '单据日期',
                field: 'bill_date'
            }, {
                check: true,
                required: true,
                name: 'department_id',
                headerClasses: 'my-special-class',
                align: 'right',
                label: '编码',
                field: 'department_id',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_name',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '名称',
                field: 'department_name',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_num',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '规格型号',
                field: 'department_num',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_brand',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '品牌',
                field: 'department_brand',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_order',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '订货号',
                field: 'department_order',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'personnel_id',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '业务员（职员）',
                field: 'personnel_id'
            }, {
                check: true,
                required: true,
                name: 'content',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '内容',
                field: 'content',
                sortable: true,
                sort: (a, b) => a - b
            },{ 
                check: false,
                required: true,
                name: 'attachment',
                headerClasses: 'my-special-class', 
                align: 'left',
                label: '附件', 
                field: 'attachment' 
            },
            { 
                check: false,
                required: true,
                name: 'notes', 
                headerClasses: 'my-special-class',
                align: 'left',
                label: '备注', 
                field: 'notes' 
            }
            
        ],
        visibleColumns: [
        ],
        tools: {
            active_index: 1,
            tabs: [
                {
                    index: 1, label:'列表信息', name:'product'
                }
            ]
        }
    },
}

const inquirySheetDetail = {
    detailSet: {
        table_class: 'layout-table',
        dense: true,
        edit: true,
        hidePagination: true,
        selected: [],
        separator: 'cell', //表格边框
        tab: 'product',
        data: [
            {
                index: 0,
                ID:1,
                inquiry_Number: '#598235',
                customer_id: '6ES72151AG400XB0',
                check: '正确1',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72151AG400XB0',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'           
            },
            {
                index: 1,
                ID:2,
                inquiry_Number: '#598235',
                customer_id: '6ES72142BD230XB8',
                check: '正确',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72151AG400XB0',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'            
            },
            {
                index: 2,
                ID:3,
                inquiry_Number: '#598235',
                customer_id: '6ES72162BD230XB8',
                check: '正确',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 3,
                ID:4,
                inquiry_Number: '#598235',
                customer_id: '6ES72211BH220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 5,
                ID:5,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 6,
                ID:6,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 7,
                ID:7,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 6,
                ID:8,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            },
            {
                index: 7,
                ID:9,
                inquiry_Number: '#598235',
                customer_id: '6ES72231HF220XA8',
                check: '错误',
                opportunity_id: 'SIE.FA.PG212.6ES72151AG400XB0',
                bill_date: '6ES72162BD230XB8',
                department_id: '#598235',
                department_name: '低压电机',
                department_num: '1FK7083-2AF71-1UA0',
                department_brand: '西门子',
                department_order: '6ES72162BD230XB8',
                personnel_id: '王某某'
            }
        ],
        columns: [
            {
                check: true,
                name: 'ID',
                required: true,
                headerStyle: 'max-width: 50px', 
                style: 'max-width: 50px',
                label: '序号',
                align: 'center',
                field: 'ID',
                format: (val) => `${val}`
            },{
                check: true,
                name: 'inquiry_Number',
                headerClasses: 'my-special-class',
                required: true,
                label: '单据编号',
                align: 'right',
                field: 'inquiry_Number',
                format: (val) => `${val}`
            }, {
                check: true,
                required: true,
                name: 'customer_id',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '客户内码',
                field: 'customer_id'
            },{
                check: true,
                required: true,
                name: 'opportunity_id',
                headerClasses: 'my-special-class',
                label: '商机/项目内码',
                align: 'left',
                field: 'opportunity_id',
                sortable: true,
            }, {
                check: true,
                required: true,
                name: 'bill_date',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '单据日期',
                field: 'bill_date'
            }, {
                check: true,
                required: true,
                name: 'department_id',
                headerClasses: 'my-special-class',
                align: 'right',
                label: '编码',
                field: 'department_id',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_name',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '名称',
                field: 'department_name',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_num',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '规格型号',
                field: 'department_num',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_brand',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '品牌',
                field: 'department_brand',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'department_order',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '订货号',
                field: 'department_order',
                sortable: true,
                sort: (a, b) => a - b
            },{
                check: true,
                required: true,
                name: 'personnel_id',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '业务员（职员）',
                field: 'personnel_id'
            }, {
                check: true,
                required: true,
                name: 'content',
                headerClasses: 'my-special-class',
                align: 'left',
                label: '内容',
                field: 'content',
                sortable: true,
                sort: (a, b) => a - b
            },{ 
                check: false,
                required: true,
                name: 'attachment',
                headerClasses: 'my-special-class', 
                align: 'left',
                label: '附件', 
                field: 'attachment' 
            },
            { 
                check: false,
                required: true,
                name: 'notes', 
                headerClasses: 'my-special-class',
                align: 'left',
                label: '备注', 
                field: 'notes' 
            }
            
        ],
        visibleColumns: [
        ],
        tools: {
            active_index: 1,
            // btns: [
            //     {index:1, label: '新增行',  showlabel: false, type: 'text', methods: 'addRow'},
            //     {index:2, label: '删除行',  showlabel: false, type: 'text', methods: 'deleteRow'}
            // ],
            // toolsbar: true
            tabs: [
                {
                    index: 1, label:'列表信息', name:'product'
                }
            ]
        }
    }
}

const operation_tools = {    
    buttonsSet: {
        toolClick: ['department', 'tool_click'],
        buttons: [
            {
                index: 1, label: '新的', sign:'add', type: 'square', showlabel:false,
                disable:false, btnClick: false, variant:{}
            },
        ]
    }

}

const state = () => ({
    inquirySheet: Object.assign({},inquirySheetform, inquirySheetData, inquirySheetDetail, inquirySheetOptions, operation_tools)
})
const getters = {  
    formSet: state => state.inquirySheet.formSet,
    tableSet: state => state.inquirySheet.tableSet,
    detailSet: state => state.inquirySheet.detailSet,
}

const mutations = {
    // 更改所有button状态
    ['tool_click'](state, data){
        state.department.buttonsSet.buttons.forEach(val=> {
            if(val.index === data.index) {
                val.btnClick = !val.btnClick
            }
        })
    }   
}

const actions = {
    // 查询上级部门列表，因为和搜索部门的接口一样，但是不需要传name
    async ['fetch-higher-department-list']({state,dispatch}){
        await API.fetchDepartmentList().then(res => {
            if(res.status == 200){
                state.department.dep = res.data
            }
        })
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}