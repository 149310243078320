/***********
 * create by hu at 20230427
 * 延迟加载路由配置
 *
 * 待处理问题：
 * 1，不同类型用户登录变更页面布局
 * 2，不同登录方式变更布局
 *
 * 参数注释：
 * {requiresAuth} 认证路由
 **********/




const routes = [
    {path: '/login/', name: 'login', component: () => import('../views/login.vue')},
    {
        path: "/data/",
        name: "data",
        component: () => import( "../views/layout/base.vue"),
        meta: {requiresAuth: true},
        children: [
            {
                path: "/data/material/",
                name: "materialdata",
                component: () => import( '../views/data/material.vue'),
                meta: {requiresAuth: true},
            }
        ]
    },
    //电子订单
    // {
    //     path: '/e-order/orders/',
    //     name: 'eorder',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //
    //     children: [
    //         {
    //             path: '/e-order/orders/purchase/',
    //             name: 'purchase',
    //             component: () => import('../views/e-order/purchase.vue')
    //         },
    //         {path: '/e-order/orders/', name: 'orders', component: () => import('../views/e-order/orders.vue')},
    //         {
    //             path: '/e-order/orders/payable/',
    //             name: 'payable',
    //             component: () => import('../views/e-order/payable.vue')
    //         },
    //         {
    //             path: '/e-order/orders/serialnumber/',
    //             name: 'serialnumber',
    //             component: () => import('../views/e-order/basic/serialnumber.vue')
    //         },
    //         {path: '/e-order/orders/spr/', name: 'spr', component: () => import('../views/e-order/basic/spr.vue')},
    //     ]
    // },
    // {
    //     path: '/e-order/receipt/',
    //     name: 'receipt',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {
    //             path: '/e-order/receipt/notice/',
    //             name: 'notice',
    //             component: () => import('../views/e-order/receipt/notice.vue')
    //         },
    //
    //     ]
    // },
    // // 询价单
    // {
    //     path: '/e-order/inquirySheet/',
    //     name: 'inquirySheet',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: false},
    //     children: [
    //         {
    //             path: '/e-order/inquirySheet/',
    //             name: 'inquirySheetIndex',
    //             component: () => import('../views/e-order/inquirySheet/index.vue')
    //         },
    //         {
    //             path: '/e-order/inquirySheet/add/',
    //             name: 'inquirySheetAdd',
    //             component: () => import('../views/e-order/inquirySheet/add.vue')
    //         },
    //         {
    //             path: '/e-order/inquirySheet/detail/',
    //             name: 'inquirySheetDetail',
    //             component: () => import('../views/e-order/inquirySheet/detail.vue')
    //         },
    //     ]
    // },
    //
    // {
    //     path: '/e-order/basic/',
    //     name: 'basic',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {
    //             path: '/e-order/basic/material/',
    //             name: 'siemensmaterial',
    //             component: () => import('../views/e-order/basic/material.vue')
    //         },
    //         {
    //             path: '/e-order/basic/bom/',
    //             name: 'siemensbom',
    //             component: () => import('../views/e-order/basic/bom.vue')
    //         },
    //         {
    //             path: '/e-order/basic/serialnumber/',
    //             name: 'serialnumber',
    //             component: () => import('../views/e-order/basic/serialnumber.vue')
    //         },
    //         {
    //             path: '/e-order/basic/receipt-address/',
    //             name: 'receiptaddress',
    //             component: () => import('../views/e-order/basic/receiptaddress.vue')
    //         },
    //     ]
    // },
    // {
    //     path: '/e-order/charts/',
    //     name: 'charts',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {path: '/e-order/charts/price/', name: 'pricechart', component: () => import('../views/charts/price.vue')},
    //         {
    //             path: '/e-order/charts/delivery/',
    //             name: 'deliverychart',
    //             component: () => import('../views/charts/delivery.vue')
    //         },
    //     ]
    // },
    // //选型工具
    // {
    //     path: '/selector/',
    //     name: 'selector',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {path: '/selector/', name: 'selector', component: () => import('../views/selector/selector.vue')},
    //     ]
    // },
    // {
    //     path: '/functional/',
    //     name: 'functional',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {
    //             path: '/functional/organization/',
    //             name: 'organization',
    //             component: () => import('../views/crm/organization.vue'),
    //             meta: {requiresAuth: true}
    //         },
    //         {
    //             path: '/functional/department/',
    //             name: 'department',
    //             component: () => import('../views/crm/department.vue'),
    //             meta: {requiresAuth: true}
    //         },
    //         {
    //             path: '/functional/actor/', name: 'actor', component: () => import('../views/crm/actor.vue'),
    //             meta: {requiresAuth: true}
    //         },
    //         {
    //             path: '/functional/member/', name: 'member', component: () => import('../views/crm/member.vue'),
    //             meta: {requiresAuth: false}
    //         },
    //     ]
    // },
    //
    // {
    //     path: '/functional/',
    //     name: 'functional',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: false},
    //     children: [
    //         {
    //             path: '/functional/organization/',
    //             name: 'organization',
    //             component: () => import('../views/crm/organization.vue'),
    //             meta: {requiresAuth: true}
    //         },
    //         {
    //             path: '/functional/department/',
    //             name: 'department',
    //             component: () => import('../views/crm/department.vue'),
    //             meta: {requiresAuth: false}
    //         },
    //         {
    //             path: '/functional/actor/', name: 'actor', component: () => import('../views/crm/actor.vue'),
    //             meta: {requiresAuth: true}
    //         },
    //         {
    //             path: '/functional/member/', name: 'member', component: () => import('../views/crm/member.vue'),
    //             meta: {requiresAuth: false}
    //         },
    //
    //
    //     ]
    // },
    // //报价单
    //
    // {
    //     path: '/client/',
    //     name: 'client',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {
    //             path: '/client/quotation/list/',
    //             name: 'quotationList',
    //             component: () => import('../views/quotation/list.vue')
    //         },
    //         {path: '/client/expense/', name: 'expense', component: () => import('../views/expense/list.vue')},
    //         {
    //             path: '/client/quotation/test/',
    //             name: 'quotationTest',
    //             component: () => import('../views/quotation/test.vue')
    //         },
    //     ]
    // },
    // //物料综合分析
    // {
    //     path: '/report/',
    //     name: 'report',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {path: '/report/material/', name: 'material', component: () => import('../views/report/material/list.vue')},
    //     ]
    // },
    // {
    //     path: '/client/',
    //     name: 'client',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: false},
    //     children: [
    //         {
    //             path: '/client/quotation/list/',
    //             name: 'quotationList',
    //             component: () => import('../views/quotation/list.vue')
    //         },
    //         {
    //             path: '/client/quotation/detail/',
    //             name: 'quotationDetail',
    //             component: () => import('../views/quotation/detail.vue')
    //         },
    //         {
    //             path: '/client/quotation/test/',
    //             name: 'quotationTest',
    //             component: () => import('../views/quotation/test.vue')
    //         },
    //         //销售订单
    //         {path: '/client/sales/list/', name: 'sales', component: () => import('../views/sales/index.vue')},
    //
    //     ]
    // },
    // {
    //     path: '/customer/',
    //     name: 'customer',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {
    //             path: '/customer/', name: 'customerlist', component: () => import('../views/crm/customer/customer.vue'),
    //             meta: {requiresAuth: true}
    //         },
    //
    //
    //     ]
    // },
    // {
    //     path: '/system/',
    //     name: 'system',
    //     component: () => import('../views/layout/base.vue'),
    //     meta: {requiresAuth: true},
    //     children: [
    //         {
    //             path: '/system/client-service/',
    //             name: 'clientservice',
    //             component: () => import('../views/system/windows.vue'),
    //             meta: {requiresAuth: true}
    //         },
    //
    //
    //     ]
    // },


    //待完善
    {
        path: '/', name: 'main', component: () => import('../views/layout/base.vue'), meta: {requiresAuth: true},
        children: [
            {path: '/', name: 'home', component: () => import('../views/home.vue'), meta: {requiresAuth: true}},
            {
                path: '/configuration/user/', name: 'userset', component: () => import('../views/user.vue'),
                meta: {requiresAuth: true}
            },
            {
                path: '/configuration/system/', name: 'systemset', component: () => import('../views/system.vue'),
                meta: {requiresAuth: true}
            },
        ]
    },


]

export default routes