const priceData = {
    PriceSearchFormSet: { form_class: '', body: [        
        {index: 1, label: '开始时间', name: 'F_KeyDate', value: '', type: 'input', placeholder: '', 
        filled:true, outlined: true},
        {index: 2, label: '订货号', name: 'F_KeyDate', value: '', type: 'input', placeholder: '输入订货号查询', 
        filled:true, outlined: true},
       
    ]},
    PriceSource: {
        table_class: 'layout-table',
        data: [{'F_MATERIAL_NO': '3VA10102ED320AA0','F_PRICE': 649, 'F_UPDATE_AT': '2022-11-20','F_CHANGE': 0.25, 'F_MIN_PRICE': 545, 'F_MAX_PRICE': 649}],
        visibleColumns: [],
        selection: 'single',
        selected_change:['charts', 'price_selected'],
        selected: [],
        columns: [
            {check: true,required: false,name: 'F_MATERIAL_NO',label: '订货号',align: 'left',field: 'F_MATERIAL_NO',sortable: true,
            format: (val) => `${val?val:''}`},             
            {check: true,required: false,name: 'F_PRICE',label: '最新价格',align: 'right',field: 'F_PRICE',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_UPDATE_AT',label: '更新时间',align: 'left',field: 'F_UPDATE_AT',sortable: true,
            format: (val) => `${val?val:''}`},            
            {check: true,required: false,name: 'F_CHANGE',label: '变化趋势(%)',align: 'center',field: 'F_CHANGE',sortable: true,
            format: (val) => `${val?val:''}`, colorSet:'+-'},               
            {check: true,required: false,name: 'F_MIN_PRICE',label: '历史最低',align: 'right',field: 'F_MIN_PRICE',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_MAX_PRICE',label: '历史最高',align: 'right',field: 'F_MAX_PRICE',sortable: true,
            format: (val) => `${val?val:''}`},
            
        ]
    },
    PriceChart:{
        title: '',        
        x: ['2020-12-01','2021-3-12','2021-5-20','2021-6-15','2021-8-1','2021-8-25','2021-10-01','2021-12-05','2021-12-25','2022-01-30',
        '2022-03-20', '2022-05-20', '2022-07-03', '2022-09-20', '2022-11-20'],
        y: [
            {name: '列表价', data: [545,580,568,572,590,620,590,620,545,580,568,572,590,620,649]},
        ],
    }
    
}
const deliveryData = {
    DeliverySearchFormSet: { form_class: '', body: [        
        {index: 1, label: '开始时间', name: 'F_KeyDate', value: '', type: 'input', placeholder: '', 
        filled:true, outlined: true},
        {index: 2, label: '订货号', name: 'F_KeyDate', value: '', type: 'input', placeholder: '输入订货号查询', 
        filled:true, outlined: true},
       
    ]},
    DeliverySource: {
        table_class: 'layout-table',
        data: [{'F_MATERIAL_NO': '6ES72111BE400XB0','F_PRICE': 60, 'F_UPDATE_AT': '2022-11-20','F_CHANGE': -30, 'F_MIN_PRICE': 30, 'F_MAX_PRICE': 180}],
        visibleColumns: [],
        selection: 'single',
        selected_change:['charts', 'delivery_selected'],
        selected: [],
        columns: [
            {check: true,required: false,name: 'F_MATERIAL_NO',label: '订货号',align: 'left',field: 'F_MATERIAL_NO',sortable: true,
            format: (val) => `${val?val:''}`},             
            {check: true,required: false,name: 'F_PRICE',label: '当前货期',align: 'right',field: 'F_PRICE',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_UPDATE_AT',label: '更新时间',align: 'left',field: 'F_UPDATE_AT',sortable: true,
            format: (val) => `${val?val:''}`},            
            {check: true,required: false,name: 'F_CHANGE',label: '变化趋势(天)',align: 'center',field: 'F_CHANGE',sortable: true,
            format: (val) => `${val?val:''}`, colorSet:'+-'},               
            {check: true,required: false,name: 'F_MIN_PRICE',label: '历史最低',align: 'right',field: 'F_MIN_PRICE',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'F_MAX_PRICE',label: '历史最高',align: 'right',field: 'F_MAX_PRICE',sortable: true,
            format: (val) => `${val?val:''}`},
            
        ]
    },
    DeliveryChart:{
        title: '',        
        x: ['2020-12-01','2021-3-12','2021-5-20','2021-6-15','2021-8-1','2021-8-25','2021-10-01','2021-12-05','2021-12-25','2022-01-30',
        '2022-03-20', '2022-05-20', '2022-07-03', '2022-09-20', '2022-11-20'],
        y: [
            {name: '货期', data: [30,90,120,90,120,30,60,120,160,180,160,90,120,90,60]},
        ],
    }
    
}

const state = () => ({
    charts: Object.assign({}, priceData, deliveryData)
})
const getters = {
    getPriceSearchFormSet: state => state.charts.PriceSearchFormSet,
    getPriceSource: state => state.charts.PriceSource,
    getPriceChart: state => state.charts.PriceChart,
    getDeliverySearchFormSet: state => state.charts.DeliverySearchFormSet,
    getDeliverySource: state => state.charts.DeliverySource,
    getDeliveryChart: state => state.charts.DeliveryChart,
    
}
const mutations = {
   
}
const user = localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {}
const actions = {
    async ['price_selected']({state, dispatch}, payload){
        state.charts.PriceSource.selected = payload 
        if(payload.length>0){
            state.charts.PriceChart.title =   '物料'+ payload[0].F_MATERIAL_NO + '列表价趋势'
        }        
    },
    async ['delivery_selected']({state, dispatch}, payload){
        state.charts.DeliverySource.selected = payload 
        if(payload.length>0){
            state.charts.DeliveryChart.title =   '物料'+ payload[0].F_MATERIAL_NO + '货期趋势'
        }        
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}