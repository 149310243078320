import API from '../../apis'
const formData = {
    listForm: {
        align: 'left',
        style: '',
        vertical: true,
        body: [
            {
                index: 1, vertical: true,
                // submit: ['quotation', 'listSearch'], 不想用此方法调用
                items: [
                    {
                        index: 0, showlabel: true, label: '关键字查询', placeholder: '输入关键字查询', name: 'search', value: '', type: 'input', filled: false,
                        outlined: true,
                        width: '200px',
                    },
                    {
                        index: 5, label: '查询', type: 'submit', filled: false, outlined: true, showlabel: false,
                        disable: false, variant: { icon: 'fas fa-search' }
                    },
                ]
            },
        ]
    },
    detailForm: {
        // align: 'center',
        style: '',
        vertical: true,
        body: [
            {
                index: 1, vertical: true, submit: ['member', 'fetch-list'],
                items: [
                    {
                        index: 0, showlabel: true, label: '客户内码', placeholder: '', name: '', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', denseOpts: true,
                        variant: {
                            //  origin: ['quotation', 'customerList', 'getCustomerList'], fields: ['company', 'id']
                        },
                    },

                    {
                        index: 0, showlabel: true, label: '客户编码', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '客户名称', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '简称缩写', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '客户级别', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '税号', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '行业', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '类型', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '区域', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '地址', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '公司网址', placeholder: '', name: '', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                    {
                        index: 0, showlabel: true, label: '备注', placeholder: '', name: 'notes', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                ]
            },
        ]
    }
}
const tableData = {
    basicTable: {
        table_class: 'layout-table',
        height: '400px',
        separator: 'cell', //表格边框
        // row_key: 'id',
        hidePagination: false,
        dense: true,
        edit: false, //带不带编辑功能
        selection: 'multiple',
        selected: [],
        visibleColumns: [
            'customer_id', 'customer_number', 'customer_name', 'shortened', 'erp_number', 'level', 'tax', 'branch', 'type', 'territory', 'address', 'url', 'notes'
        ],
        data: [
            {
                id: '1', customer_id: '客户内码1', customer_number: '34RFSDFD123', customer_name: '客户名称1', shortened: 'FWCT', erp_number: '3445DFDSB8789', level: '3',
                tax: '432154646576724324N', branch: '制造业', type: '电商', territory: '华东区域', address: '普陀区绿洲中环333弄', url: 'http://fwct.com', notes: '备注信息111'
            },
            {
                id: '2', customer_id: '客户内码1', customer_number: '34RFSDFD123', customer_name: '客户名称1', shortened: 'FWCT', erp_number: '3445DFDSB8789', level: '3',
                tax: '432154646576724324N', branch: '制造业', type: '电商', territory: '华东区域', address: '普陀区绿洲中环333弄', url: 'http://fwct.com', notes: '备注信息222'
            },
            {
                id: '3', customer_id: '客户内码1', customer_number: '34RFSDFD123', customer_name: '客户名称1', shortened: 'FWCT', erp_number: '3445DFDSB8789', level: '3',
                tax: '432154646576724324N', branch: '制造业', type: '电商', territory: '华东区域', address: '普陀区绿洲中环333弄', url: 'http://fwct.com', notes: '备注信息333'
            },
        ],
        columns: [
            {
                check: true, required: false, name: 'customer_id', label: '客户内码', align: 'left', field: 'customer_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'customer_number', label: '客户编码', align: 'left', field: 'customer_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'customer_name', label: '客户名称', align: 'left', field: 'customer_name', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'shortened', label: '简称缩写', align: 'left', field: 'shortened', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'erp_number', label: 'ERP客户编码', align: 'left', field: 'erp_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'level', label: '客户级别', align: 'right', field: 'level', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax', label: '税号', align: 'right', field: 'tax', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'branch', label: '行业', align: 'left', field: 'branch', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'type', label: '类型', align: 'left', field: 'type', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'territory', label: '区域', align: 'left', field: 'territory', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'address', label: '地址', align: 'left', field: 'address', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'url', label: '公司网址', align: 'left', field: 'url', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'notes', label: '备注', align: 'left', field: 'notes', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
        ],
    },
    demandTable: {
        table_class: 'layout-table',
        height: '200px',
        // row_key: 'id',
        separator: 'cell',
        hidePagination: true,
        dense: true,
        edit: true,
        selection: 'multiple',
        selected: [],
        visibleColumns: [
            'index', 'customer_id', 'material_name', 'material_model', 'brand', 'material_number', 'annual_consumption', 'notes'
        ],
        data: [
            { index: '1', id: '01', customer_id: '方文成拓', material_name: '伺服电机', material_model: '1FK2104-6AF10-1MA0', brand: '西门子', material_number: '1FK21046AF101MA0', annual_consumption: '2000', notes: '这是一条备注信息' },
            { index: '2', id: '02', customer_id: '方文成拓', material_name: '同步电机', material_model: '1FK2104-6AF10-1MA0', brand: '西门子', material_number: '1FK21046AF101MA0', annual_consumption: '3000', notes: '这是一条备注信息' },
            { index: '3', id: '03', customer_id: '方文成拓', material_name: '电机', material_model: '1FK2104-6AF10-1MA0', brand: '西门子', material_number: '1FK21046AF101MA0', annual_consumption: '4000', notes: '这是一条备注信息' },
        ],
        columns: [
            {
                name: 'index', label: '序号', field: 'index', align: 'center',
            },
            {
                check: true, required: false, name: 'customer_id', label: '客户内码', align: 'left', field: 'customer_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_name', label: '物料名称', align: 'left', field: 'material_name', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_model', label: '规格型号', align: 'left', field: 'material_model', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'brand', label: '品牌', align: 'left', field: 'brand', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_number', label: '订货号', align: 'left', field: 'material_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'annual_consumption', label: '年用量', align: 'right', field: 'annual_consumption', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'notes', label: '备注', align: 'left', field: 'notes', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            }
        ],
        tools: {
            active_index: 1,
            btns: [
                {
                    index: 1, label: '新增行', type: 'text', sign: "add", dense: true, flat: true,
                    disable: false, btnClick: false
                },
                {
                    index: 1, label: '删除行', type: 'text', sign: "delete", dense: true, flat: true,
                    disable: false, btnClick: false
                },
            ]
        }
    },
    opTable: {
        table_class: 'layout-table',
        height: '200px',
        // row_key: 'id',
        separator: 'cell',
        hidePagination: true,
        dense: true,
        edit: true,
        selection: 'multiple',
        selected: [],
        visibleColumns: [
            'index', 'customer_id', 'opportunity_number', 'opportunity_name', 'explain', 'customer_owner', 'owner_duty', 'owner_tel', 'owner_email', 'notes'
        ],
        data: [
            {
                index: '1', id: '001', customer_id: '方文成拓', opportunity_number: '项目编码1', opportunity_name: '长安街改造', explain: '意向客户', customer_owner: '张三', owner_duty: '项目经理', owner_tel: '13766663333',
                owner_email: '123456@163.com', notes: 'op备注信息'
            },
            {
                index: '2', id: '002', customer_id: '方文成拓', opportunity_number: '项目编码1', opportunity_name: '长安街改造', explain: '意向客户', customer_owner: '张三', owner_duty: '项目经理', owner_tel: '13766663333',
                owner_email: '123456@163.com', notes: 'op备注信息'
            },
            {
                index: '3', id: '003', customer_id: '方文成拓', opportunity_number: '项目编码1', opportunity_name: '长安街改造', explain: '意向客户', customer_owner: '张三', owner_duty: '项目经理', owner_tel: '13766663333',
                owner_email: '123456@163.com', notes: 'op备注信息'
            },

        ],
        columns: [

            {
                name: 'index', label: '序号', field: 'index', align: 'center',
            },
            {
                check: true, required: false, name: 'customer_id', label: '客户内码', align: 'left', field: 'customer_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'opportunity_number', label: '商机/项目编码', align: 'left', field: 'opportunity_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'opportunity_name', label: '商机/项目名称', align: 'left', field: 'opportunity_name', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'explain', label: '商机说明', align: 'left', field: 'explain', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'customer_owner', label: '负责人', align: 'left', field: 'customer_owner', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'owner_duty', label: '职务', align: 'left', field: 'owner_duty', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'owner_tel', label: '联系方式', align: 'right', field: 'owner_tel', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'owner_email', label: '邮箱', align: 'right', field: 'owner_email', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'notes', label: '备注', align: 'left', field: 'notes', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },

        ],
        tools: {
            active_index: 1,
            btns: [
                {
                    index: 1, label: '新增行', type: 'text', sign: "add", dense: true, flat: true,
                    disable: false, btnClick: false
                },
                {
                    index: 1, label: '删除行', type: 'text', sign: "delete", dense: true, flat: true,
                    disable: false, btnClick: false
                }
            ]
        }

    }
}
const btnData = {
    listBtn: {
        btns: [
            {
                index: 1, label: '新增', type: 'text', sign: "add", dense: true, flat: true,
                disable: false, btnClick: false, variant: { icon: 'fas fa-search' }
            },
            {
                index: 4, label: '删除', type: 'text', sign: "delete", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 3, label: '|', type: 'separate', sign: "line", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 5, label: '导入', type: 'text', sign: "import", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },

            {
                index: 7, label: '导出', type: 'text', sign: "export", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
        ],
    },
    detailBtn: {
        btns: [
            {
                index: 1, label: '返回', type: 'text', sign: "back", dense: true, flat: true,
                disable: false, btnClick: false,
            },
            {
                index: 2, label: '保存', type: 'text', sign: "save", dense: true, flat: true,
                disable: false, btnClick: false
            },

            {
                index: 2, label: '取消', type: 'text', sign: "cancel", dense: true, flat: true,
                disable: false, btnClick: false
            },
        ]
    },
}


const state = () => ({
    customer: Object.assign({}, formData, tableData, btnData)
})

const getters = {
    listBtn: state => state.customer.listBtn,
    listForm: state => state.customer.listForm,
    basicTable: state => state.customer.basicTable,
    detailBtn: state => state.customer.detailBtn,
    basicBtn: state => state.customer.basicBtn,
    detailForm: state => state.customer.detailForm,
    demandTable: state => state.customer.demandTable,
    opTable: state => state.customer.opTable,
}

const mutations = {
    //form置空
    initDetailFrom(state) {
        state.customer.detailForm.body[0].items = state.customer.detailForm.body[0].items.map(item => {
            return {
                ...item, value: null
            }
        })
    },
    //table置空
    initDetailTable(state) {
        state.customer.demandTable.data = []
        state.customer.opTable.data = []
    },
    //op表格新增行
    opAddRow(state) {
        state.customer.opTable.data.push({
            id: (Math.random() + new Date().getTime()).toString(32).slice(0, 8),
            index: state.customer.opTable.data.length + 1
        })

    },
    //demand表格新增行
    demandAddRow(state) {
        state.customer.demandTable.data.push({
            id: (Math.random() + new Date().getTime()).toString(32).slice(0, 8),
            index: state.customer.demandTable.data.length + 1
        })

    },
    GETLIST(state, data) {
        console.log(data, 'data...');
        state.customer.basicTable.data = data
    }
}

const actions = {
    async ['fetch-list']({ commit }) {
        const res = await API.CustomerList()
        if (res.status == 200) {
            console.log(res, '/');
            commit('GETLIST', res.data.results)
        }
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}