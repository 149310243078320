import Socket from '../tools/websocket'

const baseU = (localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {})
const path = baseU&&baseU[0]?baseU[0].user.path:""


const channelOptions = {
    wsurl: process.env.VUE_APP_SOCKETSERVERHOST + '/emis/' + path + '/',
    ws: null,
    hartbeat: {command: "front_check", code: "check", message: "check contect", to: "service", for:""},
    on: false,
}

const channelMessages = {
    new_message: {},
    messages: [],
}
const state = () => ({
    channel: Object.assign({}, channelOptions, channelMessages)
})

const getters = {
    getWSurl: state=> state.channel.wsurl,
    getChannel: state => state.channel.ws,
    getHartbeat: state => state.channel.hartbeat,
    isChannelOn: state => state.channel.on,
}
const mutations = {
    ['contect_channel'](state){
        state.channel.ws = new Socket()
        state.channel.on = true
    },
    ['set_message'] (state, data) {
        state.channel.new_message = data
        state.channel.messages.push(data)
    },
}
const user = localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {}
const actions = {
    async ['socket_init'] ({commit,state}) {
        let baseU = (localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {})
        let path = baseU&&baseU[0]?baseU[0].user.path:""
        state.channel.wsurl = process.env.VUE_APP_SOCKETSERVERHOST + '/emis/' + path + '/'

        commit('contect_channel')
    },
    async ['socket_close'] ({state}){
        state.channel.ws.$soctket_close()
        state.channel.on = false
    },
    async ['socket_send']({state}, payload) {
        console.log('socket send', payload)
        state.channel.ws.$soctket_emit(JSON.stringify(payload))
    },
    async ['socket_message'] ({commit, dispatch}, payload){
        console.log('socket return', payload) 
        // 过滤掉非前端处理数据和非当前用户数据
        if(payload.to!='front' || payload.message.uid!=user.id){
            return
        }
        commit('set_message', payload)
        // 接收信息

        let com = payload.command.split('__')
        let loc = com[0] + '/return_'+com[1]
        dispatch(loc,payload.message, {root: true})
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}