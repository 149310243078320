import API from '@/apis';
import utils from '@/tools/commonUtil'
function formval(v) {
    v = String(v)
    if (v.indexOf(".") != -1) {
        let n = v.split(".")[0]
        let r = v.split(".")[1]
        if (r.length < 4) {
            while (r.length < 4) {
                r = r + "0"
            }
        }
        return n + "." + r
    } else {
        return v + ".0000"
    }
}

const tableData = {

    MaterialSource: {
        // table_class: 'layout-table',
        row_key: 'ID',
        loading: false,
        data: [],
        pagination: { rowsPerPage: 10, rowsNumber: 100, page: 1 },
        fetch_type: ["client_material", "set-rows"],
        visibleColumns: [],
        columns: [
            {
                check: true,
                required: false,
                name: 'FNumber',
                label: '物料编码',
                align: 'center',
                field: 'FNumber',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
            {
                check: true,
                required: false,
                name: 'FName',
                label: '物料名称',
                align: 'left',
                field: 'FName',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
            {
                check: true,
                required: false,
                name: 'FModel',
                label: '规格型号',
                align: 'left',
                field: 'FModel',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
            {
                check: true,
                required: false,
                name: 'FSalePrice',
                label: '销售单价',
                align: 'right',
                field: 'FSalePrice',
                sortable: true,
                // format: (val) => `${formval(val)}`
                format: (val) => `${val ? val.toFixed(2) : ''}`
            },
            // {
            //     check: true,
            //     required: false,
            //     name: 'StockName',
            //     label: '仓库名称',
            //     align: 'right',
            //     field: 'StockName',
            //     sortable: true,
            //     // format: (val) => `${val ? val : ''}`
            // },
            {
                check: true,
                required: false,
                name: 'FQty',
                label: '库存数量',
                align: 'right',
                field: 'FQty',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
            {
                check: true,
                required: false,
                name: 'FQtyLock',
                label: '锁库数量',
                align: 'right',
                field: 'FQtyLock',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
            {
                check: true,
                required: false,
                name: 'AVQty',
                label: '可用数量',
                align: 'right',
                field: 'AVQty',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
            {
                check: true,
                required: false,
                name: 'FStockAwaitQty',
                label: '采购在途',
                align: 'right',
                field: 'FStockAwaitQty',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
            {
                check: true,
                required: false,
                name: 'FNOutStockQty',
                label: '销售未出库',
                align: 'right',
                field: 'FNOutStockQty',
                sortable: true,
                // format: (val) => `${val ? val : ''}`
            },
        ],
        tools: {
            // active_index: 1,
            // buttons: [
            //
            //     {index: 5, label: '导出数据', icon: 'fas fa-file-export', check_lines: 0, on_click: []},
            //
            // ]
        }
    },

}
const formData = {
    MaterialSearchFormSet: {
        form_class: '', body: [
            {
                index: 1,
                label: '物料编码搜索',
                name: 'F_KeyDate',
                value: '',
                type: 'textarea',
                placeholder: '多行输入查询',
                filled: true,
                outlined: true,
                rows: 4,
                minWidth: "300px"
            },
            {
                index: 2.,
                label: "查询",
                type: "submit",
                submit: ["client_material", "search-material"],
                icon: "fas fa-search"

            }

        ]
    },
}


const state = () => ({
    material: Object.assign({}, tableData, formData)
})

const getters = {
    material_source: state => state.material.MaterialSource,
    search_form: state => state.material.MaterialSearchFormSet

}

const mutations = {
    ['set-material'](state, data) {
        console.log(data,'data///')
        state.material.MaterialSource.data = data.data
        state.material.MaterialSource.pagination.rowsNumber = data.count
    }
}

const actions = {
    async ['init_material_data']({ state, dispatch, rootState },payload){
          // 建立长连接
          if (!rootState.channel.channel.on) {
            dispatch('channel/socket_init', {}, { root: true })
        }

        setTimeout(() => {
            if (rootState.channel.channel.on) {
                // if(state.material.MaterialSource.loading == true) return
                let msg = { command: "init_material_data", message: payload, to: "client",code: "200",for: "", }
                dispatch('channel/socket_send', msg, { root: true })
                // state.material.MaterialSource.loading == true
            }
        }, 500)
    },
    async ['return_init_material_data']({state, dispatch, commit }, payload) {
        // state.material.MaterialSource.loading = false
        console.log("return11 ", payload)
        commit('set-material', payload)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}