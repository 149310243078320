import Axios from './axios'

export default { 
    getCategoryManufacturers(){
        return Axios.get('/default/apis/v1/selector/categorys/getcategory2manufacturers/')
    },
    postCategorys(data){
        return Axios.post('/default/apis/v1/selector/categorys/postcategoryname2categorys/', data)
    },
    postSelector(data){
        return Axios.post('/default/apis/v1/selector/categorys/postselector2materials/', data)
    },

    getClient(param){
        return Axios.get('/default/apis/v1/public/client/' + param)
    }
}