import API from '../apis'
const authData = {
    authenticated: localStorage.getItem('authenticated') ? true : false,
    userBase: localStorage.getItem('userBase') ? JSON.parse(localStorage.getItem('userBase')) : {},
    userInfo: {}

}
const userData = {
    users_table_source: {
        init: ['user', 'fetch-user-list', ''],
        data: [],
        columns: [
            {check: true,required: false,name: 'company_name',label: '公司名称',align: 'left',field: 'company_name',sortable: true,
            format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'company_name',label: '公司名称',align: 'left',field: 'company_name',sortable: true,
            format: (val) => `${val?val:''}`},
        ],
        visibleColumns: [],
    },
    user_form:{
        align: 'center', style: 'width:500px',
        vertical: false,
        body: [
            
            {index: 2, vertical: false, origin:['user', 'get-user-info', 'userInfo'],submit:['user', 'post-set'], items:[
                {index: 1,showlabel:false, label: '用户密码', name: 'check_password', value: '', type: 'password', filled:false, 
                outlined: true, disable:false, required: true, placeholder: '输入用户密码'},
                {index: 2, label: '用户名', name: 'username', value: '', type: 'input', filled:false, outlined: true, 
                disable:false, placeholder: '输入新的用户名', auto_assignment: true},
                {index: 3, label: '新密码', name: 'password', value: '', type: 'password', filled:false, outlined: true, 
                disable:false, placeholder: '输入新密码'},
                {index: 4, label: '邮箱', name: 'email', value: '', type: 'input', filled:false, outlined: true, 
                disable:false, placeholder: '请输入邮箱地址', auto_assignment: true},
                {index: 5, label: '手机', name: 'mobile', value: '', type: 'input', filled:false, outlined: true, 
                disable:false, placeholder: '请输入手机号码', auto_assignment: true},
                {index: 6, label: '提交保存', type: 'submit', filled:false, outlined: true, hiddenLabel:true,
                disable:false, variant:{ icon: 'fas fa-save'}},
            ]},
            {index: 3, vertical: false, origin:['user', 'get-user-info', 'userInfo'], items:[
                {index: 1, label: '头像', name: 'avatar', value: authData.userBase.avatar, type: 'image', hiddenLabel:true, placeholder: '点击更换头像',
                variant:{ com: 'image-cutter', auto_submit: true, submit:['user', 'post-avatar']}},                
            ]},
        ]
    }
}

const organizationData = {
    organization: {
        default: localStorage.getItem('emisOrganization') ? JSON.parse(localStorage.getItem('emisOrganization')) : null,
        data: [],   
        columns: [
            {check: true,required: false,name: 'id',label: 'ID',align: 'right',field: 'id',sortable: true,
                format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'name',label: '名称',align: 'left',field: 'name',sortable: true,
                format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'mark',label: '标识',align: 'left',field: 'mark',sortable: true,
                format: (val) => `${val?val:''}`},           
        ],        
        settings: {
            title: '', row_key: 'id',  selection: 'multiple', selected_show_info: true,
        },
        editForm: {
            align: 'left', style: '',
            vertical: false,
            body: [
                {index: 1, vertical: false,submit:['member', 'fetch-list'], items:[
                    {index: 1,showlabel:true, label: '名称', name: 'name', value: '', type: 'unerr-input', filled:false, outlined: true, 
                    disable:false, placeholder: '输入机构名称', auto_assignment: true},
                    {index: 2,showlabel:true, label: '标识', name: 'mark', value: '', type: 'input', filled:false, outlined: true, 
                    disable:false, placeholder: '输入机构标识', auto_assignment: true},
                    {index: 3,showlabel:true, label: '备注', name: 'remark', value: '', type: 'textarea', filled:false, outlined: true, 
                    disable:false, placeholder: '输入备注', auto_assignment: true},
                    {index: 6, label: '提交保存', type: 'submit', filled:false, outlined: true, hiddenLabel:true,
                disable:false, variant:{ icon: 'fas fa-save'}},
                ],}
                
            ]
        }  
    },
    organizationFormSet: {
        align: 'left', style: '',
        vertical: true,
        body: [
            {index: 1, vertical: true,submit:['member', 'fetch-list'], items:[
                {index: 1,showlabel:false, label: '查询组织', name: 'search', value: '', type: 'unerr-input', filled:false, 
                outlined: true, disable:false, required: false, placeholder: '输入关键字查询', append:{
                    name: '搜索', icon: 'fas fa-search', click_submit: true, enter: true
                }},
            ],}
            
        ]
    },
    organizationbuttonsSet: {
        buttons: [
            {index: 1, sign:'add', name: '添加', desc: '', icon: 'fas fa-plus', used: 'info', show: false,
            form_assignment:false},
            {index: 2, sign:'delete', name: '删除', desc: '', icon: 'fas fa-trash', used: 'dialog', show: false, reply:{
                warning: {icon: 'fas fa-exclamation-circle', message: '您正在操作删除数据，请确认操作'}, 
                submit: ['actor', 'delete-actor']
            },size: 'width: 500px; max-width: 80vw;', no_actions: false},
        ]
    }
}
const state = () => ({
    user: Object.assign({}, authData, userData,organizationData)
})

const getters = {
    isLogin: state => state.user.authenticated,
    userBase: state => state.user.userBase,
    usersTable: state => state.user.users_table_source,
    userFormSet: state => state.user.user_form,
    userInfo: state => state.user.userInfo,
    orgList: state => state.user.organization.data,
    orgDefault: state => state.user.organization.default,
    orgTableSet: state => state.user.organization,
    orgFormSet:  state => state.user.organizationFormSet,
    orgButtonsSet:  state => state.user.organizationbuttonsSet,
    
    
}

const mutations = {
    ['set-user-info'](state, data){
        state.user.userInfo = data
    },
    ['login-success'](state, data){
        state.user.authenticated = true
        localStorage.setItem('authenticated', 1)
        state.user.userBase = data
        localStorage.setItem('userBase', JSON.stringify(data))
    },
    ['logout'](state){
        localStorage.removeItem('authenticated')
        localStorage.removeItem('userBase')       
        window.location.reload()
    },
    ['set-users-list'](state,data){
        state.user.users_table_source.data = data
    },
    ['set-organization'](state, data){
        state.user.organization.data = data
        if(!state.user.organization.default){
            localStorage.setItem('emisOrganization', data[0].id)
            state.user.organization.default = data[0].id
        }
    }
}

const actions = {
    async ['fetch-organization']({state, commit}){ 
        if (state.user.organization.data.length < 1){
            await API.OrganizationList().then(res=>{            
                commit('set-organization', res.data)
            })
        }       
        
    },
    async ['post-avatar']({state, dispatch}, payload){
        let id=state.user.userBase.id
        await API.userAvatar(id, payload).then(res=>{            
            window.location.reload()
        })
    },
    async ['post-set']({state, dispatch}, payload){
        let id=state.user.userBase.id        
        await API.userSet(id, {data: payload}).then(res=>{            
            window.location.reload()
        }).catch(err=>{
            console.log(err)
            err.data.error_name = "保存失败"
            dispatch('page/response-error', err.data, {root: true})
            dispatch('page/error', err.data, {root: true})
        })
        
    },
    async ['get-user-info']({state,commit}){
        if(!state.user.userInfo.id){
            let id=state.user.userBase.id
            await API.userInfo(id).then(res=>{                    
            commit('set-user-info', res.data)
            })
        }
        
    },
    async ['fetch-user-list']({state,commit}, payload){
        let id=state.user.userBase.id
        await API.usersList(id).then(res=>{            
           commit('set-users-list', res.data)
        })
    },
    async ['login']({commit, dispatch}, payload){
        await API.login(payload).then(res=>{
            commit('login-success', res.data.data)
            console.log(res.data)
        }).catch(err=>{
            alert(err.data.data)
            console.log(err,'kkkk');
            // dispatch('page/error', {msg:err.data.data[0],type: err.data.type[0]}, {root: true})
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}