import API from '@/apis';
import utils from '@/tools/commonUtil'
const formData = {
    quotationForm: {
        // align: 'left',
        style: '',
        vertical: true,
        body: [
            {
                index: 1, vertical: true,
                // submit: ['quotation', 'listSearch'], 不想用此方法调用
                items: [

                    {
                        index: 0, showlabel: true, label: '关键字查询', placeholder: '输入关键字查询', name: 'search', value: '', type: 'input', filled: false,
                        outlined: true,
                        width: '200px',
                    },
                    {
                        index: 5, label: '查询', type: 'submit', filled: false, outlined: true, showlabel: false,
                        disable: false, variant: { icon: 'fas fa-search' }
                    },

                    // {
                    //     index: 4, showlabel: true, label: '单据日期', placeholder: '请选择日期', name: 'bill_date', value: '', type: 'dateTime', filled: false, required: true,
                    //     outlined: true, minWidth: '120px'
                    // },
                    // {
                    //     index: 2, showlabel: true, before: 'fas fa-calendar-day', label: '日期查询', placeholder: '请选择日期', name: 'department', value: '', type: 'dateTime', filled: false,
                    //     outlined: true,
                    //     variant: {
                    //         origin: ['actor', 'getList', 'fetch-actor-list'], fields: ['name', 'id']
                    //     },
                    //     minWidth: '120px'
                    // },
                ]
            },
        ]
    },
    detailForm: {
        // align: 'center',
        style: '',
        vertical: true,
        body: [
            {
                index: 1, vertical: true, submit: ['member', 'fetch-list'],
                items: [

                    {
                        index: 0, showlabel: true, label: '单据编号', placeholder: '', name: 'quotation_Number', value: '', type: 'input', filled: true, required: true,
                        outlined: true, width: '200px', disable: true
                    },
                    {
                        index: 0, showlabel: true, label: '单据日期', placeholder: '请选择日期', name: 'bill_date', value: '', type: 'dateTime', filled: false, required: true,
                        outlined: true, width: '200px',
                    },
                    {
                        index: 0, showlabel: true, label: '客户内码', placeholder: '', name: 'department_id', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', denseOpts: true,
                        variant: {
                            origin: ['quotation', 'customerList', 'getCustomerList'], fields: ['company', 'id']
                        },
                    },
                    {
                        index: 0, showlabel: true, label: '询价单内码', placeholder: '', name: 'inquiry_number', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', denseOpts: true,
                        variant: {
                            origin: ['quotation', 'inquiryList', 'getInquiryList'], fields: ['inquiry_number', 'id']
                        },
                    },
                    {
                        index: 0, showlabel: true, label: '商机/项目内码', placeholder: '', name: 'opportunity_name', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', denseOpts: true,
                        variant: {
                            origin: ['quotation', 'opportunityList', 'getOpportunityList'], fields: ['opportunity_name', 'id']
                        },
                    },
                    {
                        index: 0, showlabel: true, label: '部门', placeholder: '', name: 'department_name', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', denseOpts: true,
                        // variant: {
                        //     origin: ['quotation', 'opportunityList', 'getOpportunityList'], fields: ['opportunity_name', 'id']
                        // },
                    },
                    {
                        index: 0, showlabel: true, label: '业务员', placeholder: '', name: 'personnel_id', value: '', type: 'select', filled: false, required: true,
                        outlined: true, width: '200px', denseOpts: true,
                        // variant: {
                        //     origin: ['quotation', 'opportunityList', 'getOpportunityList'], fields: ['opportunity_name', 'id']
                        // },
                    },
                    {
                        index: 0, showlabel: true, label: '备注', placeholder: '', name: 'notes', value: '', type: 'input', filled: false, required: true,
                        outlined: true, width: '200px', disable: false
                    },
                ]
            },
        ]
    }
}
const tableData = {
    quotationTable: {
        table_class: 'layout-table',
        height: '400px',
        separator: 'cell', //表格边框
        // row_key: 'id',
        hidePagination: false,
        dense: true,
        edit: false, //带不带编辑功能
        selection: 'multiple',
        selected: [],
        visibleColumns: ['quotation_Number', 'bill_date', 'department_id', 'inquiry_number', 'opportunity_name', 'department_name',
            'personnel_id', 'notes', 'quotation_id', 'material_name', 'material_model', 'brand', 'material_number', 'date_delivery', 'qty',
            'tax_list_price', 'tax_rate', 'list_price', 'standard_discount', 'special_discount', 'discount', 'tax_price', 'price', 'tax_amount',
            'tax_deduction', 'amount', 'deduction', 'cost_discount', 'assess_profit', 'profit_rate', 'notes'
        ],
        data: [
            {
                id: '001', index: 0, quotation_Number: 'QUO-2023-554531689149528', bill_date: '2023-03-01 00:00', department_id: '方文成拓', inquiry_number: '询价单1', opportunity_name: '商机1', department_name: '部门1', personnel_id: '张三', notes: '备注一条信息',
                list: [
                    {
                        id: '01',
                        quotation_id: '1FK21046AF101MA0',
                        material_name: '伺服电机',
                        material_model: '1FK2104-6AF10-1MA0',
                        brand: '西门子',
                        material_number: '1FK21046AF101MA0',
                        date_delivery: '2022-09-08',
                        qty: '10',
                        tax_list_price: '100',
                        tax_rate: '13',
                        list_price: '120',
                        standard_discount: '10',
                        special_discount: '8',
                        discount: '15',
                        tax_price: '80',
                        price: '90',
                        tax_amount: '200000',
                        tax_deduction: '1800000',
                        amount: '30000',
                        deduction: '20000',
                        cost_discount: '100000',
                        assess_profit: '8778',
                        profit_rate: '200000',
                        notes: '备注一条信息',
                    },
                    {
                        id: '02',
                        quotation_id: '1FK70322AK711RH2',
                        material_name: '同步电机',
                        material_model: '1FK7032-2AK71-1RH2',
                        brand: '西门子',
                        material_number: '1FK70322AK711RH2',
                        date_delivery: '2022-09-08',
                        qty: '10',
                        tax_list_price: '100',
                        tax_rate: '13',
                        list_price: '120',
                        standard_discount: '10',
                        special_discount: '8',
                        discount: '15',
                        tax_price: '80',
                        price: '90',
                        tax_amount: '200000',
                        tax_deduction: '1800000',
                        amount: '30000',
                        deduction: '20000',
                        cost_discount: '100000',
                        assess_profit: '8778',
                        profit_rate: '200000',
                        notes: '备注一条信息',
                    },
                    {
                        id: '03',
                        quotation_id: '1FK70422AK711RB0',
                        material_name: '电机',
                        material_model: '1FK7042-2AK71-1RB0',
                        brand: '西门子',
                        material_number: '1FK70422AK711RB0',
                        date_delivery: '2022-09-08',
                        qty: '10',
                        tax_list_price: '100',
                        tax_rate: '13',
                        list_price: '120',
                        standard_discount: '10',
                        special_discount: '8',
                        discount: '15',
                        tax_price: '80',
                        price: '90',
                        tax_amount: '200000',
                        tax_deduction: '1800000',
                        amount: '30000',
                        deduction: '20000',
                        cost_discount: '100000',
                        assess_profit: '8778',
                        profit_rate: '200000',
                        notes: '备注一条信息',
                    },
                ]
            },

        ],
        // data:
        //     Array(3).fill().map((item, index) => {
        //         return { index:index,id: index, quotation_Number: 'QUO-2023-554531689149528', bill_date: '2023-03-01 00:00', department_id: '方文成拓', personnel_id: '张三', notes: '备注一条信息', status: 0, creater_id: '张三', create_datetime: '2023-02-02', updater_id: '李四', update_datetime: '2023-03-05' }
        //     }),

        columns: [
            {
                check: true, required: false, name: 'quotation_Number', label: '单据编号', align: 'right', field: 'quotation_Number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'bill_date', label: '单据日期', align: 'right', field: 'bill_date', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'department_id', label: '客户内码', align: 'left', field: 'department_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'inquiry_number', label: '询价单内码', align: 'left', field: 'inquiry_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'opportunity_name', label: '商机/项目内码', align: 'left', field: 'opportunity_name', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'department_name', label: '部门', align: 'left', field: 'department_name', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },

            {
                check: true, required: false, name: 'personnel_id', label: '业务员（职员)', align: 'left', field: 'personnel_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'notes', label: '备注', align: 'left', field: 'notes', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            // {
            //     check: true, required: false, name: 'status', label: '数据状态', align: 'right', field: 'status', sortable: true,
            //     format: (val) => `${val == 0 ? '删除' : val == 1 ? '默认' : '禁用'}`
            // },
            // {
            //     check: true, required: false, name: 'creater_id', label: '创建者', align: 'right', field: 'creater_id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            // {
            //     check: true, required: false, name: 'create_datetime', label: '创建时间', align: 'right', field: 'create_datetime', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            // {
            //     check: true, required: false, name: 'updater_id', label: '更新者', align: 'right', field: 'updater_id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            // {
            //     check: true, required: false, name: 'update_datetime', label: '更新时间', align: 'right', field: 'update_datetime', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            //===================================
            {
                check: true, required: false, name: 'quotation_id', label: '编码', align: 'right', field: 'quotation_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_name', label: '物料名称', align: 'left', field: 'material_name', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_model', label: '规格型号', align: 'right', field: 'material_model', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'brand', label: '品牌', align: 'left', field: 'brand', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_number', label: '订货号', align: 'right', field: 'material_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'date_delivery', label: '交货日期', align: 'right', field: 'date_delivery', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'qty', label: '数量', align: 'right', field: 'qty', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_list_price', label: '列表价(含税)', align: 'right', field: 'tax_list_price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_rate', label: '税率(%)', align: 'right', field: 'tax_rate', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'

            },
            {
                check: true, required: false, name: 'list_price', label: '面价(未税)', align: 'right', field: 'list_price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'standard_discount', label: '标准下浮(%)', align: 'right', field: 'standard_discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'special_discount', label: '特殊下浮(%)', align: 'right', field: 'special_discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'discount', label: '实际下浮(%)', align: 'right', field: 'discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_price', label: '实际含税单价', align: 'right', field: 'tax_price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'price', label: '实际未税单价', align: 'right', field: 'price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_amount', label: '实际含税金额', align: 'right', field: 'tax_amount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_deduction', label: '含税下浮额', align: 'right', field: 'tax_deduction', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'amount', label: '实际未税金额', align: 'right', field: 'amount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'deduction', label: '未税下浮额', align: 'right', field: 'deduction', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'cost_discount', label: '核算成本折扣(%)', align: 'right', field: 'cost_discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'assess_profit', label: '核算利润', align: 'right', field: 'assess_profit', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'profit_rate', label: '核算利润率', align: 'right', field: 'profit_rate', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'notes', label: '备注', align: 'left', field: 'notes', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
        ],
    },
    detailTable: {
        table_class: 'layout-table',
        height: '400px',
        // row_key: 'id',
        separator: 'cell',
        hidePagination: true,
        dense: true,
        edit: true,
        selection: 'multiple',
        selected: [],
        // visibleColumns: ['quotation_id', 'material_name', 'material_model', 'brand', 'material_number', 'date_delivery', 'qty',
        //     'tax_list_price', 'tax_rate', 'list_price', 'standard_discount', 'special_discount', 'discount', 'tax_price', 'price', 'tax_amount',
        //     'tax_deduction', 'amount', 'deduction', 'cost_discount', 'assess_profit', 'profit_rate', 'notes'
        // ],
        visibleColumns: ['index', 'quotation_id', 'material_name', 'material_model', 'brand', 'material_number', 'date_delivery', 'qty',
            'tax_list_price', 'tax_rate', 'standard_discount', 'special_discount',
            'cost_discount', 'notes'
        ],
        data: [],
        // data:
        //     Array(4).fill().map((item, index) => {
        //         return {
        //             id: index,
        //             material_name: '插座',
        //             material_model: '小型KJH',
        //             brand: '公牛',
        //             material_number: 'JI97878',
        //             date_delivery: '2022-09-08',
        //             qty: '900',
        //             tax_list_price: '100',
        //             tax_rate: '13',
        //             list_price: '120',
        //             standard_discount: '10',
        //             special_discount: '8',
        //             discount: '15',
        //             tax_price: '80',
        //             price: '90',
        //             tax_amount: '200000',
        //             tax_deduction: '1800000',
        //             amount: '30000',
        //             deduction: '20000',
        //             cost_discount: '100000',
        //             assess_profit: '8778',
        //             profit_rate: '200000',
        //             notes: '备注信息:箱子太小...',
        //             status: 2,
        //             creater_id: '王五',
        //             create_datetime: '2023-01-03',
        //             updater_id: '赵六',
        //             update_datetime: '2023-02-01'
        //         }
        //     }),
        columns: [
            { name: 'index', label: '序号', field: 'index', align: 'center' },
            {
                check: true, required: false, name: 'quotation_id', label: '编码', align: 'right', field: 'quotation_id', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_name', label: '物料名称', align: 'left', field: 'material_name', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_model', label: '规格型号', align: 'right', field: 'material_model', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'brand', label: '品牌', align: 'left', field: 'brand', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'material_number', label: '订货号', align: 'right', field: 'material_number', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'date_delivery', label: '交货日期', align: 'right', field: 'date_delivery', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'qty', label: '数量', align: 'right', field: 'qty', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_list_price', label: '列表价(含税)', align: 'right', field: 'tax_list_price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_rate', label: '税率(%)', align: 'right', field: 'tax_rate', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'list_price', label: '面价(未税)', align: 'right', field: 'list_price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'standard_discount', label: '标准下浮(%)', align: 'right', field: 'standard_discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'special_discount', label: '特殊下浮(%)', align: 'right', field: 'special_discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'discount', label: '实际下浮(%)', align: 'right', field: 'discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_price', label: '实际含税单价', align: 'right', field: 'tax_price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'price', label: '实际未税单价', align: 'right', field: 'price', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_amount', label: '实际含税金额', align: 'right', field: 'tax_amount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'tax_deduction', label: '含税下浮额', align: 'right', field: 'tax_deduction', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'amount', label: '实际未税金额', align: 'right', field: 'amount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'deduction', label: '未税下浮额', align: 'right', field: 'deduction', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'cost_discount', label: '核算成本折扣(%)', align: 'right', field: 'cost_discount', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'assess_profit', label: '核算利润', align: 'right', field: 'assess_profit', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'profit_rate', label: '核算利润率', align: 'right', field: 'profit_rate', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            {
                check: true, required: false, name: 'notes', label: '备注', align: 'left', field: 'notes', sortable: true,
                format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            },
            // {
            //     check: true, required: false, name: 'status', label: '数据状态', align: 'right', field: 'status', sortable: true,
            //     format: (val) => `${val == 0 ? '删除' : val == 1 ? '默认' : '禁用'}`
            // },
            // {
            //     check: true, required: false, name: 'creater_id', label: '创建者', align: 'right', field: 'creater_id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            // {
            //     check: true, required: false, name: 'create_datetime', label: '创建时间', align: 'right', field: 'create_datetime', sortable: true,
            //     format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            //     edit: false,//当前列是否支持编辑
            // },
            // {
            //     check: true, required: false, name: 'updater_id', label: '更新者', align: 'right', field: 'updater_id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            // {
            //     check: true, required: false, name: 'update_datetime', label: '更新时间', align: 'right', field: 'update_datetime', sortable: true,
            //     format: (val) => `${val ? val : ''}`, headerClasses: 'my-special-class'
            //     edit: false,//当前列是否支持编辑
            // }
        ],
        tools: {
            active_index: 1,
            btns: [
                {
                    index: 1, label: '新增行', type: 'text', sign: "add", dense: true, flat: true,
                    disable: false, btnClick: false
                },
                {
                    index: 1, label: '删除行', type: 'text', sign: "delete", dense: true, flat: true,
                    disable: false, btnClick: false
                }
            ]
        }
    },
}
const btnData = {
    listBtn: {

        btns: [
            {
                index: 1, label: '新增', type: 'text', sign: "add", dense: true, flat: true,
                disable: false, btnClick: false, variant: { icon: 'fas fa-search' }
            },
            {
                index: 4, label: '删除', type: 'text', sign: "delete", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 3, label: '|', type: 'separate', sign: "line", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 5, label: '导入', type: 'text', sign: "import", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },

            {
                index: 7, label: '导出', type: 'text', sign: "export", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
            {
                index: 2, label: '详情', type: 'text', sign: "detail", dense: true, flat: true,
                disable: false, variant: { icon: 'fas fa-wrench' }, btnClick: false
            },
        ],
    },

    detailBtn: {
        btns: [
            {
                index: 1, label: '返回', type: 'text', sign: "back", dense: true, flat: true,
                disable: false, btnClick: false,
            },

            {
                index: 1, label: '保存', type: 'text', sign: "save", dense: true, flat: true,
                disable: false, btnClick: false, variant: { icon: 'fas fa-search' }
            },
            {
                index: 2, label: '取消', type: 'text', sign: "cancel", dense: true, flat: true,
                disable: false, btnClick: false
            },
        ]
    }
}


const state = () => ({
    quotation: Object.assign({}, formData, tableData, btnData)
})

const getters = {
    BtnSet: state => state.quotation.listBtn,
    FormSet: state => state.quotation.quotationForm,
    TableSet: state => state.quotation.quotationTable,
    DetailBtn: state => state.quotation.detailBtn,
    DetailTable: state => state.quotation.detailTable,
    DetailForm: state => state.quotation.detailForm,
    customerList: state => state.quotation.customerList || [],
    inquiryList: state => state.quotation.inquiryList || [],
    opportunityList: state => state.quotation.opportunityList || [],
}

const mutations = {
    //form置空
    initDetailFrom(state) {
        state.quotation.detailForm.body[0].items = state.quotation.detailForm.body[0].items.map(item => {
            return {
                ...item, value: null
            }
        })
    },
    //table置空
    initDetailTable(state) {
        state.quotation.detailTable.data = []
    },
    //列表页的选中状态置空
    clearListSelect(state) {
        state.quotation.quotationTable.selected = []
    },
    //详情页的选中状态置空
    clearDetailSelect(state) {
        state.quotation.detailTable.selected = []
    },
    //拷贝数据
    copyData(state) {
        console.log('jjjj');
        //list
        let oldForm = {}
        state.quotation.detailForm.body[0].items.map(item => {
            oldForm[item.name] = item.value
        })
        console.log(oldForm, '88////');
        state.quotation.copyForm = oldForm
        //list
        state.quotation.copyTable = JSON.parse(JSON.stringify(state.quotation.detailTable.data)) || []
    },
    //list接口
    GETLIST(state, data) {
        // state.quotation.quotationTable.data = data.map((item, index) => {
        //     return { ...item, index: index }
        // })

        //todo  state.quotation.quotationTable.data后期换城接口数据
        const list2 = state.quotation.quotationTable.data.map((item, index) => {
            return { ...item, index: index }
        })
        const res = list2.map(item => {
            return item.list.map((son, index) => {
                if (index == 0) {
                    return { ...son, ...item }
                } else {
                    return { ...son, id: item.id, list: item.list }
                }
            })
        })
        let list3 = []
        res.map(item => {
            item.map(son => {
                list3.push(son)
            })
        })
        console.log(list3, '///');
        state.quotation.quotationTable.data = list3

    },
    //客户内码
    CUSTOMERLIST(state, data) {
        state.quotation.customerList = data
    },
    //询价单
    INQUIRYLIST(state, data) {
        state.quotation.inquiryList = data
    },
    //商机
    OPPORTUNITYLIST(state, data) {
        state.quotation.opportunityList = data
    },
    //新增 接口生成id
    SETID(state, data) {
        state.quotation.id = data.id
        //回显单据编号
        state.quotation.detailForm.body[0].items[0].value = data.quotation_Number
    },
    //更新ID 
    UPDATEID(state, id) {
        state.quotation.id = id
    },
    //表体回显
    // GETDETAILTABLE(state, data) {
    //     state.quotation.detailTable.data = data
    // },

    //找到select的那条数据
    getDetail(state, data) {
        state.quotation.detailForm.body[0].items[0].value = data.quotation_Number
        state.quotation.detailForm.body[0].items[1].value = data.bill_date
        state.quotation.detailForm.body[0].items.forEach((item, index) => {
            item.value = data[item.name]
        })
        console.log(data, 'detail///');
        state.quotation.detailTable.data = data.list.map((item, index) => {
            return { ...item, index: index + 1 }
        })


    },
    //新增行
    addRow(state) {
        state.quotation.detailTable.data.push({
            id: (Math.random() + new Date().getTime()).toString(32).slice(0, 8),
            index: state.quotation.detailTable.data.length + 1
        })
        mutations.hiddenColumns(state)
    },
    //隐藏计算列
    hiddenColumns(state) {
        console.log('jjj');
        state.quotation.detailTable.visibleColumns = ['quotation_id', 'material_name', 'material_model', 'brand', 'material_number', 'date_delivery', 'qty',
            'tax_list_price', 'tax_rate', 'standard_discount', 'special_discount',
            'cost_discount', 'notes'
        ]
    },
    //删除行 假删除
    deleteRow(state) {
        const select = state.quotation.detailTable.selected
        select.forEach(ite => {
            state.quotation.detailTable.data.forEach((item, index) => {
                if (item.id == ite.id) {
                    state.quotation.detailTable.data.splice(index, 1)
                }
            })
        })
    },
}

const actions = {
    async addOrEdit({ state, commit, dispatch }) {
        let form = {}
        state.quotation.detailForm.body[0].items.map(item => {
            form[item.name] = item.value
        })
        let table = {}
        table.list = state.quotation.detailTable.data
        //form=================
        const newParams = utils.funDifference(form, state.quotation.copyForm)
        const params = utils.filterEmptyKey(newParams)
        delete params.quotation_Number
        delete params.customer_id
        console.log(params, 'pppp');
        //如果有修改,先put再get  否则直接get
        if (!utils.isEmpty(params)) {
            console.log('form提交的数据', state.quotation.id)
            const id = state.quotation.id
            const res1 = await API.quotationAddHeader(id, params)
            if (res1.status == 200) {
                dispatch('getList')
            }
        } else {
            dispatch('getList')
        }
        //table=======
        //编辑时候新增
        if (table.list && table.list.length && state.quotation.copyTable.length) {
            const list = table.list.map(item => {
                return utils.filterEmptyKey(item)
            })
            //put
            let editData = {}, editList = []
            list.map(item => {
                state.quotation.copyTable.map(ite => {
                    if (item.id == ite.id) {
                        utils.isEmpty(utils.funDifference(item, ite)) ? '' : editList.push({ id: ite.id, ...utils.funDifference(item, ite) })
                    }
                })
            })
            editData.list = editList
            editList.length ? await API.quotationEditBody(editData) : ''
            //post
            let addData = {}, arr = []
            arr = list.filter(x => !state.quotation.copyTable.some(y => y.id === x.id))
            arr = arr.map(item => {
                return utils.filterEmptyAndKey('id', item)
            })
            addData.list = arr
            addData.id = state.quotation.id
            arr.length ? await API.quotationAddBody(addData) : ''
        } else {
            //新增时的新增
            table.list = table.list.map(item => utils.filterEmptyAndKey('id', item))
            table.id = state.quotation.id
            await API.quotationAddBody(table)

        }


        //关闭抽屉时候,清空form和table
        commit('initDetailFrom')
        commit('initDetailTable')
    },
    async getList({ commit }) {
        // const res = await API.quotationList()
        // if (res.status == '200') {
        //     commit('GETLIST', res.data.results)
        // }

        commit('GETLIST')
    },
    //客户列表
    async getCustomerList({ commit }) {
        const res = await API.customerList()
        if (res.status == 200) {
            commit('CUSTOMERLIST', res.data.results)
        }
    },
    // 询价列表
    async getInquiryList({ commit }) {
        const res = await API.inquiryList()
        if (res.status == 200) {
            commit('INQUIRYLIST', res.data.results)
        }
    },
    //商机列表
    async getOpportunityList({ commit }) {
        const res = await API.opportunityList()
        if (res.status == 200) {
            commit('OPPORTUNITYLIST', res.data.results)
        }
    },
    //列表删除
    async deleteList({ state, dispatch }) {
        const select = state.quotation.quotationTable.selected
        const id = select.map(item => item.id)
        const res = await API.quotationMutildelete({ ids: id })
        if (res.status == 200) {
            //更新列表
            dispatch('getList')
        }
    },
    //新增生成id
    async getId({ commit }) {
        const res = await API.quotationGetId()
        if (res.status == 200) {
            console.log(res.data, 'id00');
            commit('SETID', res.data)
            commit("copyData");

        }
    },
    //详情的表体
    // async getDetailTable({ state, commit }, payload) {
    //     const res = await API.quotationGetDetailTable(payload[0].id)
    //     if (res.status == 200) {
    //         commit('GETDETAILTABLE', res.data.results)
    //         commit('UPDATEID', payload[0].id)
    //         commit("copyData");
    //     }
    // },
    //详情删除
    async deleteDetailRow({ state, commit, dispatch }) {
        const select = state.quotation.detailTable.selected
        const newIds = select.map(item => item.id)
        const oldIds = state.quotation.copyTable.map(item => item.id)
        console.log(newIds, oldIds, 'id///');
        const ids = newIds.filter(x => oldIds.some(y => y == x))
        console.log(ids, '删除ids');
        await API.quotationDetaildelete({ ids })
    },
    //按条件搜索
    async listSearch(context, payload) {
        console.log(payload, '0==');
        let sub_data = {}
        payload[0].items.forEach(item => {
            if (item.value) { sub_data[item.name] = item.value }
        })
        if (Object.keys(sub_data).length > 0) {
            console.log(sub_data, 'jjjj');
            let customer_id = sub_data.customer_id || null,
                opportunity_id = sub_data.opportunity_id || null,
                inquiry_id = sub_data.inquiry_id || null,
                // bill_date_after,
                // bill_date_before,
                // create_at_after,
                // create_at_before,
                search = sub_data.search || null
            await API.quotationSearch(customer_id, opportunity_id, inquiry_id, '', '', '', '', search)
        }
    },
    //保存
    onSave({ state }) {
        state.quotation.detailTable.visibleColumns = ['quotation_id', 'material_name', 'material_model', 'brand', 'material_number', 'date_delivery', 'qty',
            'tax_list_price', 'tax_rate', 'list_price', 'standard_discount', 'special_discount', 'discount', 'tax_price', 'price', 'tax_amount',
            'tax_deduction', 'amount', 'deduction', 'cost_discount', 'assess_profit', 'profit_rate', 'notes'
        ]
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}