// 普通api
import Axios from './axios'
const path = JSON.parse(localStorage.getItem('userBase')) ? JSON.parse(localStorage.getItem('userBase'))[0].user.path : ''
export default {
  addPostHeader(config) {
    return {
      ...config,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
    }
  },
  getCaptcha() {
    return Axios.get("/refresh_captcha/")
  },
  login(user, config) {
    return Axios.post("/default/token/", user, config)
  },

  // dep 上级部门列表
  fetchDepartmentList() {
    return Axios.get(`${path}/apis/v1/department`)
  },
  // dep 带name模糊搜索
  fetchDepartmentListByName(name) {
    return Axios.get(`${path}/apis/v1/department/?search=${name}`)
  },
  // dep 查部门树
  fetchDepartmentTree() {
    return Axios.get(`${path}/apis/v1/department/tree`)
  },
  // dep 查部门节点详情
  getDepartmentTreeByName(name) {
    return Axios.get(`${path}/apis/v1/department/?name=${name}`)
  },
  // dep 新增节点
  addDepartmentTree(data) {
    return Axios.post(`${path}/apis/v1/department/`, data)
  },
  // dep 修改节点
  editDepartmentTree(id, data) {
    return Axios.put(`${path}/apis/v1/department/${id}/`, data)
  },
  // dep 删除节点
  delDepartmentTree(names) {
    return Axios.post(`${path}/apis/v1/department/multidelete/`, {
      names
    })
  },

  //actor
  fetchActorList() {
    return Axios.get(`${path}/apis/v1/actor/`)
  },
  //member
  fetchMemberList() {
    return Axios.get(`${path}/apis/v1/member/`)
  },
  //organization
  OrganizationList() {
    return Axios.get(`${path}/apis/v1/organization/`)
  },
  //customer
  CustomerList() {
    return Axios.get(`${path}/apis/v1/customer/`)
  },
  //quotation
  quotationList() {
    return Axios.get(`${path}/apis/v1/quotation/`)
  },
  //搜索
  quotationSearch(
    customer_id,
    opportunity_id,
    inquiry_id,
    bill_date_after,
    bill_date_before,
    create_at_after,
    create_at_before,
    search
    ) {
    // return Axios.get(`${path}/apis/v1/quotation/?
    // customer_id=${customer_id}
    // &opportunity_id=${opportunity_id}
    // &inquiry_id=${inquiry_id}
    // &bill_date_after=${bill_date_after}
    // &bill_date_before=${bill_date_before}
    // &create_at_after=${create_at_after}
    // &create_at_before=${create_at_before}
    // &search=${search}`)
    return Axios.get(`${path}/apis/v1/quotation/?
    customer_id=${customer_id}`
   )
  },
  quotationAddHeader(id, data) {
    return Axios.put(`${path}/apis/v1/quotation/${id}/`, data)
  },
  quotationAddBody(data) {
    return Axios.post(`${path}/apis/v1/quotation_entry/`, data)
  },
  quotationEditBody(data) {
    return Axios.post(`${path}/apis/v1/quotation_entry/multiupdate/`, data)
  },
  //列表页table删除
  quotationMutildelete(data) {
    return Axios.post(`${path}/apis/v1/quotation/multidelete/`, data)
  },
  quotationGetId() {
    return Axios.post(`${path}/apis/v1/quotation/auto_create/`)
  },
  quotationGetDetailTable(id) {
    return Axios.get(`${path}/apis/v1/quotation_entry/?quotation_id=${id}`)
  },
  //详情table删除
  quotationDetaildelete(data) {
    return Axios.post(`${path}/apis/v1/quotation_entry/multidelete/`, data)
  },
  //客户
  customerList() {
    return Axios.get(`${path}/apis/v1/customer/`)
  },
  //询价单
  inquiryList() {
    return Axios.get(`${path}/apis/v1/inquiry/`)
  },
  //商机
  opportunityList() {
    return Axios.get(`${path}/apis/v1/opportunity/`)
  }
}

