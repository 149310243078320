// import { set } from 'core-js/core/dict'
import API from '../../apis'
import date from 'quasar/src/utils/date.js';
const operation_tools = {    
    buttonsSet: {
        toolClick: ['department', 'tool_click'],
        buttons: [
            {
                index: 1, label: '新的', dense:true, flat:true, sign:'add', type: 'square', showlabel:false,
                disable:false, btnClick: false, variant:{}
            },
            {
                index: 2, label: '修改', dense:true, flat:true, sign:'edit', type: 'text',
                disable:false, variant:{ icon: ''}, btnClick: false
            },
            {
                index: 3, label: '|', dense:true, flat:true, type: 'separate', showlabel:false,
                disable:false, variant:{ icon: ''}, btnClick: false
            },
            {
                index: 4, label: '保存', dense:true, flat:true, sign:'save', type: 'text',
                disable:false, variant:{ icon: ''}, btnClick: false
            },
            {
                index: 5, label: '取消', dense:true, flat:true, sign:'cancel', type: 'text',
                disable:false, variant:{ icon: ''}, btnClick: false
            },
            {
                index: 6, label: '|', dense:true, flat:true, type: 'separate', showlabel:false,
                disable:false, variant:{ icon: ''}, btnClick: false
            },
            {
                index: 7, label: '删除', dense:true, flat:true, sign:'del', type: 'text',
                disable:false, variant:{ icon: ''}, btnClick: false, delClick: false
            },
            {
                index: 8, label: '退出', dense:true, flat:true, sign:'exit', type: 'text',
                disable:false, variant:{ icon: ''}, btnClick: false, delClick: false
            },
            {
                index: 9, type: 'round',  sign:'set', showlabel:false,
                disable:false, variant:{ icon: 'fas fa-wrench'}, btnClick: false
            }
        ]
    }

}
const memberform = {
    formSet: {
        align: 'left', style: '',
        vertical: true,
        body: [
            {index: 1, vertical: true,submit:['member', 'fetch-list'], btnClick: ['member', 'dialog_open'], items:[
                
                {index: 2,showlabel:true, label: '所在部门', placeholder: '输入关键字查询', name: 'member', value: '', type: 'select',filled:false, denseOpts: true,
                outlined: true, variant:{
                    origin: ['department', 'getList', 'fetch-department-list'], fields:['name', 'id']
                },minWidth: '160px'},
                {index: 1,showlabel:true, label: '查询成员', name: 'search', value: '', type: 'input', filled:false, 
                outlined: true, disable:false, required: false, placeholder: '输入关键字查询', append:{
                    name: '搜索', icon: 'fas fa-search', click_submit: true, enter: true
                },minWidth: '160px'},
                {index: 4, label: '查询', type: 'submit', filled:false, outlined: true, showlabel:false,
                disable:false, variant:{ icon: 'fas fa-search'}},
                {index: 5, type: 'round', filled:false, showlabel:false,
                disable:false, variant:{ icon: 'fas fa-wrench'}, btnClick: false},
                {index: 6, type: 'round', filled:false, showlabel:false,
                disable:false, variant:{ icon: 'fas fa-cloud-upload-alt'}, btnClick: false},
                // {index: 7, type: 'squareGroup', showlabel:false, 
                // disable:false, variant:{origin: ['今天', '本周', '本月', '今日收货']}},
            ]},
        ]
    }
}

const membertable = {
    tableSet: {
        data: [], 
        pagination: {
            search_keys: '',
            page: 1,
            count: 0
        },       
        columns: [
            {check: true,required: false,name: 'id',label: 'ID',align: 'right',field: 'id',sortable: true,
                format: (val) => `${val?val:''}`}, 
            {check: true,required: false,name: 'name',label: '名称',align: 'left',field: 'name',sortable: true,
                format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'idn',label: '工号',align: 'left',field: 'idn',sortable: true,
                format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'tel',label: '座机',align: 'left',field: 'tel',sortable: true,
                format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'user.sex_',label: '性别',align: 'left',field: 'user.sex_',sortable: true,
                format: (val) => `${val?val:''}`},
            {check: true,required: false,name: 'user.email',label: '邮箱',align: 'left',field: 'user.email',sortable: true,
                format: (val) => `${val?val:''}`},
            
        ],
        selected: {change: ['member', 'table-selectd-change'], data: []},
        settings: {
            title: '', row_key: 'id', init: ['member', 'fetch-list'], loading: false, selection: 'multiple',
            grid: true, scroll_loading: true
        },
        tools: {
            click: ['actor', 'tools_set'],
            active: 0,
            buttons: [
                {index: 1, sign:'delete', name: '删除角色', desc: '', icon: 'fas fa-paste', used: 'dialog', show: false, reply:{
                    warning: {icon: 'fas fa-exclamation-circle', message: '您正在操作删除数据，请确认操作'}, 
                    submit: ['actor', 'delete-actor']
                },size: 'width: 500px; max-width: 80vw;', no_actions: false},
            ]
        }
    }
}
const state = () => ({
    member: Object.assign({}, memberform, membertable,operation_tools)
})

const getters = {
    FormSet: state => state.member.formSet,
    TableSet: state => state.member.tableSet,
    ButtonsSet: state => state.member.buttonsSet,
}

const mutations = {
    ['set-list-page'](state, data){
        state.member.tableSet.data = data.results
        state.member.tableSet.pagination.count = data.count
    },
    // button示例1
    ['dialog_open'](state, data){
        state.member.formSet.body[0].items.forEach(val=> {
            if(val.index === data.index) {
                val.btnClick = !val.btnClick
            }
        })
    }
}

const actions = {
    async ['tools_set']({state, dispatch}, payload){
        let tool = state.actor.tableSet.tools
        tool.active = payload.index
        let button = tool.buttons.filter(it=>{
            return it.index==payload.index
        })[0]
        //合法性验证
        let selected = state.actor.tableSet.selected.data
        if (button.sign==''){
            if (selected.length !=1){
                let error = {error_name: '选择错误', data: '请选择一行数据!'}              
                dispatch('page/response-error',error, {root: true})
                return
            }
        }
        button.show = !button.show
    },
    async ['delete-actor']({commit}, payload){
        console.log(payload)
    },
    async ['table-selectd-change']({state}, payload){
        
        state.member.tableSet.selected.data = payload
    },
    async ['search-list']({commit}, payload){
        console.log(payload)
    },
    async ['fetch-list']({state,commit}){        
        if(state.member.tableSet.data.length < 1){
            await API.fetchMemberList().then(res=>{                
                commit('set-list-page', res.data)
            })
        }
        
    }
   
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}