import { createStore } from 'vuex'
import user from './user'
import page from './page'
import department from './crm/department'
import actor from './crm/actor'
import member from './crm/member'
import customer from './crm/customer'
import selector from './selector/selector'
import e2e from './e-order'
import eorder from './e-order/eorder'
import e2ebasic from './e-order/basic'
import receipt from './e-order/receipt'
import charts from './charts'
import channel from './channel'
import client from './client'
import quotation from './client/quotation.js'
import expense from './client/expense.js'
import client_material from './client/material'
import material from './report/material.js'
import inquirySheet from './inquirySheet'
import sales from './sales'
const store = createStore({
    modules: {
        e2e,
        eorder,
        e2ebasic,
        receipt,
        charts,
        selector,
        channel,
        client,
        user,
        page,
        department,
        actor,
        member,
        customer,
        quotation,

        expense,
        material,
        inquirySheet,


        client_material

    }
})


export default store